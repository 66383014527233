import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

export class CustomReuseStrategy implements RouteReuseStrategy {

    private routeStore = new Map<string, any>();
    private saveRoute:boolean=false;
    private attachRoute:boolean=false;
    validDetachRoutes:string[] = ['channels/:id','channels/:id/create-event', 'channels/:id/edit-event/:eventId',
    'channels/:id/create-named-tag', 'channels/:id/edit-named-tag/:tagId', 'channels/:id/create-page', 'channels/:id/edit-page/:pageId' ,'home']

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if(route.data['key']==='headeraction') this.attachRoute=true;
        const shouldSaveRoute = this.validDetachRoutes.slice(0,-1).includes(route.routeConfig?.path || '')
        return shouldSaveRoute && this.saveRoute;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        if(route.routeConfig?.path) this.routeStore.set(route.routeConfig.path+route.params['id'], handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if(route.data['key']==='headeraction') this.saveRoute=true
        const path = route.routeConfig?.path
        if(['channels/:id/create-event', 'channels/:id/edit-event/:eventId', 
        'channels/:id/create-named-tag', 'channels/:id/edit-named-tag/:tagId', 'channels/:id/create-page', 'channels/:id/edit-page/:pageId'].includes(path as string) ) this.saveRoute=false
        if(path && !this.validDetachRoutes.includes(path) && route.data['key']!=='headeraction'){
            this.routeStore.clear();
            this.attachRoute=false;
            this.saveRoute=false;
            return false
        }
        
        return  !!path && this.attachRoute && !!this.routeStore.get(path+route.params['id']);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        const path = route.routeConfig?.path+route.params['id'];
        if(!path) return null
        const toBeReturned = this.routeStore.get(path) as DetachedRouteHandle;
        this.attachRoute=false;
        this.saveRoute=false;
        return toBeReturned
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

}