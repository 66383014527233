import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ErrorModalComponent } from '../home/push-notification/error-modal.component';
import { ImageCropperComponent } from '../home/push-notification/image-cropper/image-cropper.component';
import { CancelPopupComponent } from '../material-dialog/cancel-popup/cancel-popup.component';
import { ConfirmCancelPopupComponent } from '../material-dialog/confirm-cancel-popup/confirm-cancel-popup.component';
import { DeletePopupComponent } from '../material-dialog/delete-popup/delete-popup.component';
import { EventsTagsImageComponent } from '../material-dialog/events-tags-image/events-tags-image.component';
import { FailurePopupComponent } from '../material-dialog/failure-popup/failure-popup.component';
import { InternetLostComponent } from '../material-dialog/internet-lost/internet-lost.component';
import { NewChannelExperienceComponent } from '../material-dialog/new-channel-experience/new-channel-experience.component';
import { SuccessPopupComponent } from '../material-dialog/success-popup/success-popup.component';
import { TeamTagsComponent } from '../material-dialog/team-tags/team-tags.component';
import { VideoBuyersListComponent } from '../material-dialog/video-buyers-list/video-buyers-list.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog
  ) { }
  public openDialog(typeofChannel: boolean, dimensions:DialogPosition): MatDialogRef<NewChannelExperienceComponent> {

    const dialogRef: MatDialogRef<NewChannelExperienceComponent> =
      this.dialog.open(NewChannelExperienceComponent, {
        hasBackdrop: true,
        height: 'auto',
        width: '307px',
        panelClass: 'channel-exp-panel',
        data: {
          typeofChannel: typeofChannel
        },
        position:dimensions,
        // maxHeight: '40vh'
      })
    return dialogRef
  }
  
  openImageCropperDialog(data: any): MatDialogRef<ImageCropperComponent>{
    const dialogRef = this.dialog.open(ImageCropperComponent,{
      width:'565px',
      data: data,
      position:{
        'top':'20px'
      },
      disableClose:true,
      panelClass:'image-cropper',
      maxHeight:'90vh'
    })
    return dialogRef
  }

  openErrorDialog(data: any): MatDialogRef<ErrorModalComponent>{
    const dialogRef = this.dialog.open(ErrorModalComponent,{
      width:'500px',
      position:{
        'top':'150px'
      },
      panelClass:'error-dialog',
      disableClose:true,
      data:data
    });
    return dialogRef
  }

  openFailureDialog(data: any): MatDialogRef<FailurePopupComponent>{
    const dialogRef = this.dialog.open(FailurePopupComponent,{
      width:'500px',
      position:{
        'top':'150px'
      },
      panelClass:'failure-message-dialog',
      disableClose:true,
      data:data
    });
    return dialogRef
  }

  openTeamTagDialog(data: any): MatDialogRef<TeamTagsComponent>{
    const tagDialog= this.dialog.open(TeamTagsComponent,{
      width: '450px',
      maxHeight: '620px',
      hasBackdrop: true,
      panelClass: 'team-tags-events',
      position: {top: '45px'},
      disableClose: true,
      data: data,
      maxWidth:'95vw',
    })
    return tagDialog
  }

  openInternetLostDialog() : MatDialogRef<InternetLostComponent>{
    const internetLost = this.dialog.open(InternetLostComponent, {
      width: "450px",
      panelClass: "internet-lost",
      position: {
        top: "180px",
      },
      // disableClose: true,
    });
    return internetLost
  }

  openSuccessPop(data: any,width:string = '468px'): MatDialogRef<SuccessPopupComponent>{
    const successDialog = this.dialog.open(SuccessPopupComponent,{
      data:data,
      width:width,
      position:{
        'top':'13px'
      },
      disableClose:true,
      panelClass:"success-message-dialog"
    })
    return successDialog
  }

  openConfirmCancelPopup(data: any,width='468px'): MatDialogRef<ConfirmCancelPopupComponent>{
    const confirmRemove = this.dialog.open(ConfirmCancelPopupComponent,{
      data: data,
      panelClass:"confirm-cancel",
      width,
      position:{
        'top':'150px'
      },
      disableClose:true
    })
    return confirmRemove
  }

  openCancelConfirm(moduleName:string, tabName?:string){
    const data = {
      header:`Cancel ${moduleName}${tabName?tabName:(moduleName.toLowerCase()==='channel'?' Updation':'')}`,
      message:`Are you sure you want to cancel updating the ${moduleName.toLowerCase()} ${tabName? tabName.toLowerCase(): ''}?`,
      action:'none'
    }
    const confirmCancel = this.dialog.open(ConfirmCancelPopupComponent,{
      panelClass:"confirm-cancel",
      width:'468px',
      position:{
        'top':'150px'
      },
      data: data,
      disableClose: true
    });

    return  firstValueFrom(confirmCancel.afterClosed())
  }

  openCancelPopup(data: any): MatDialogRef<CancelPopupComponent>{
    const cancelPopup = this.dialog.open(CancelPopupComponent,{
      data: data,
      panelClass: "cancel-action",
      width: "468px",
      position: {
        top: "150px",
      },
      disableClose: true,
    })

    return cancelPopup
    
  }

  openDeletePopup(data: any,width:string='438px'): MatDialogRef<DeletePopupComponent> {
    const deleteDialog = this.dialog.open(DeletePopupComponent, {
      data: data,
      panelClass: "delete-popup",
      width: width,
      position: {
        top: "150px",
      },
      disableClose: true,
    });

    return deleteDialog
  }

  openVideoBuyersListDialog(data: any): MatDialogRef<VideoBuyersListComponent>{
    const dialog = this.dialog.open(VideoBuyersListComponent, {
      data: data,
      width: "500px",
      maxWidth: "82vw",
      position: {
        top: "150px",
      },
      disableClose: true,
      panelClass: "video-buyers-list",
    });
    return dialog
  }

  openUpdateEventsTagsImage(data: any, dimensions: DialogPosition): MatDialogRef<EventsTagsImageComponent>{
    const dialogRef= this.dialog.open(EventsTagsImageComponent, {
        hasBackdrop: true,
        height: '272px',
        // width: '482px',
        panelClass: 'update-image',
        data: data,
        position:dimensions,
      })
    return dialogRef
  }
}
