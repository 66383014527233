import { auditTime, map, take, tap } from "rxjs/operators";

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { deleteField } from "@angular/fire/firestore";
import { Branding, Event, NamedTag, Page } from "@yoimo/interfaces";
import firebase from 'firebase/compat/app';
import { chunk } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class MasterChannelService {
  
  endOfChannel: boolean = false
  endOfEvent: boolean = false
  eventSearchText: string = ''
  tagsSearchText: string = ''
  pagesSearchText: string = ''
  attachedChannelSearchText: string = ''
  rowId: string = ''
  // bucsMasterChannelInfo:Record<string,string> = {
  //   channelId:environment.masterChannelId
  // }

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions
  ) { }

  async getVideoSource(docId:string){
    return this.firestore.collection("channels").doc(docId).collection("videos").ref.get()
  }

  async getSource(docId:string, subCollection: string){
    return this.firestore.collection("channels").doc(docId).collection(subCollection).ref.get()
  }

  async getEventSource(docId:string){
    return this.firestore.collection("channels").doc(docId).collection("events").ref.get()
  }

  async getPageSource(docId:string){
    return this.firestore.collection("channels").doc(docId).collection("pages").ref.get()
  }

  async getTagSource(docId:string){
    return this.firestore.collection("channels").doc(docId).collection("tags").ref.get()
  }

  getEventById(channelDocId:string, eventDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("events").doc(eventDocId).valueChanges({idField: "docId"})
  }

  getEvents(channelDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("events", (ref)=> ref.orderBy("startTime",'desc')).valueChanges({idField:"docId"})
  }

  getPages(channelDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("pages").get()
  }

  getPageById(channelDocId:string, pageDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("pages").doc(pageDocId).get().pipe(
      map((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data() as Page; 
          const id = snapshot.id;       
          return { id, ...data };   
        } else {
          return null;
        }
      }))
  }

  createEvent(channelDocId:string, eventDocId:string, obj:any){
    return this.firestore.collection("channels").doc(channelDocId).collection("events").doc(eventDocId).set(obj);
  }

  createPage(channelDocId:string, pageDocId:string, obj:any){
    return this.firestore.collection("channels").doc(channelDocId).collection("pages").doc(pageDocId).set(obj, { merge: true });
  }

  editEvent(channelDocId:string, eventDocId:string, obj:any){
    return this.firestore.collection("channels").doc(channelDocId).collection("events").doc(eventDocId).update(obj);
  }
  
  updateEvents(channelDocId: string,eventDocId:string, obj: any,type:'DETAILS'|'TICKETS'|'PAGES', pagePropsToDelete: Partial<keyof Page>[] = []): Promise<void> {
    const channelCollectionRef = this.firestore.collection('channels').doc(channelDocId).collection("events");
    
    return new Promise<void>((resolve, reject) => {
      channelCollectionRef.doc(eventDocId).get().subscribe((doc) => {
        if (doc.exists) {
          const eventData: any = doc.data();
          switch(type){
            case "PAGES":
              const pages = eventData.pages;
  
                if (pages && pages.length > 0) {
                  pages[0] = { ...pages[0], ...obj };
                  for (const property of pagePropsToDelete) delete pages[0][property];
          
                  channelCollectionRef.doc(eventDocId).update({
                    pages: pages,
                  })
                  .then(() => {
                    resolve(); 
                  })
                  .catch((error) => {
                    console.error('Error updating document: ', error);
                    reject(error); 
                  });
                } else {
                  reject(new Error('No pages found in the document.'));
                }
                break;    
            default:
                channelCollectionRef.doc(eventDocId).update({
                  ...obj
                })
                .then(() => {
                  resolve(); 
                })
                .catch((error) => {
                  console.error('Error updating document: ', error);
                  reject(error); 
                });
          }
        } else {
          reject(new Error('Document does not exist.'));
        }
      });
    });
  }

  editEventTickets(channelDocId:string, eventDocId:string, obj:any){
    return this.firestore.collection("channels").doc(channelDocId).collection("events").doc(eventDocId).update(obj);
  }

  updatePublishStateEvent(channelId:string, eventId:string, status:boolean){
    const obj = {
      published: status
    }
    return this.firestore.collection("channels").doc(channelId).collection("events").doc(eventId).update(obj);
  }

  updatePagesState(channelId:string, pageId:string, obj:{
    status?: string } | {
    active?: boolean
  }){
    return this.firestore.collection("channels").doc(channelId).collection("pages").doc(pageId).update(obj);
  }

  updateUnarchiveStateEvent(channelId:string, eventId:string, status:boolean){
    const obj = {
      archived: status
    }
    return this.firestore.collection("channels").doc(channelId).collection("events").doc(eventId).update(obj);
  }
  async updateArchiveStateEvent(channelId:string, eventId:string){
    return await this.functions.httpsCallable("events-archiveevent")({eventId: eventId, channelId: channelId}).toPromise()
  }
  createNamedTag(channelDocId:string, tagDocId:string, obj:any){
    return this.firestore.collection("channels").doc(channelDocId).collection("tags").doc(tagDocId).set(obj);
  }

  setNamedTagPage(channelDocId:string, tagDocId:string, page:any){
    let obj = {
      pages: page
    }
    return this.firestore.collection("channels").doc(channelDocId).collection("tags").doc(tagDocId).set(obj, {merge:true});
  }

  updateNamedTag(channelDocId: string,tagDocId:string, obj: any,type:'DETAILS'|'PAGES', pagePropsToDelete: Partial<keyof Page>[] = []): Promise<void> {
    const channelCollectionRef = this.firestore.collection('channels').doc(channelDocId).collection("tags");
    
    return new Promise<void>((resolve, reject) => {
      channelCollectionRef.doc(tagDocId).get().subscribe((doc) => {
        if (doc.exists) {
          const tagData: any = doc.data();
          switch(type){
            case "PAGES":
              const pages = tagData.pages;
  
                if (pages && pages.length > 0) {
                  pages[0] = { ...pages[0], ...obj };
                  for (const property of pagePropsToDelete) delete pages[0][property]
          
                  channelCollectionRef.doc(tagDocId).update({
                    pages: pages,
                  })
                  .then(() => {
                    resolve(); 
                  })
                  .catch((error) => {
                    console.error('Error updating document: ', error);
                    reject(error); 
                  });
                } else {
                  reject(new Error('No pages found in the document.'));
                }
                break;    
            default:
                channelCollectionRef.doc(tagDocId).update({
                  ...obj
                })
                .then(() => {
                  resolve(); 
                })
                .catch((error) => {
                  console.error('Error updating document: ', error);
                  reject(error); 
                });
          }
        } else {
          reject(new Error('Document does not exist.'));
        }
      });
    });
  }

  updatePages(channelDocId: string, pageId:string, obj: any, pagePropsToDelete: Partial<keyof Page>[] = []): Promise<void> {
    const channelCollectionRef = this.firestore.collection('channels').doc(channelDocId).collection("pages");
    
    return new Promise<void>((resolve, reject) => {
      channelCollectionRef.doc(pageId).get().subscribe((doc) => {
        if (doc.exists) {
          let pages: any = doc.data();
          if (Object.keys(pages).length) {
            pages = { ...pages, ...obj };
            for (const property of pagePropsToDelete) pages[property] = deleteField();
    
            channelCollectionRef.doc(pageId).update({
              ...pages
            })
            .then(() => {
              resolve(); 
            })
            .catch((error) => {
              console.error('Error updating document: ', error);
              reject(error); 
            });
          } else {
            reject(new Error('No pages found in the document.'));
          }
        } else {
          reject(new Error('Document does not exist.'));
        }
      });
    });
  }

  getNamedTags(channelDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("tags").valueChanges({idField:"docId"})
  }

  getNamedTagById(channelDocId: string, tagDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("tags").doc(tagDocId).valueChanges()
  }
  getNamedTagImages(channelDocId: string, tagDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection<NamedTag>("tags").doc(tagDocId).valueChanges().pipe(
      map((namedTag)=> {
        if(!namedTag) return null
        return {
          coverImage: namedTag.cover,
          bannerImages: namedTag?.pages?.[0].brandingOverride?.bannerImages
        }
      })
    )
  }
  getEventImages(channelDocId: string, eventDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection<Event>("events").doc(eventDocId).valueChanges().pipe(
      map((event) => {
        if(!event) return null
        return {
          posterImage: event.posterImage,
          bannerImages: event.pages[0].brandingOverride?.bannerImages
        }
      })
    )
  }
  updateNamedTagById(channelDocId: string, tagDocId: string, obj: any){
    return this.firestore.collection("channels").doc(channelDocId).collection("tags").doc(tagDocId).update(obj);
  }

  createUpload(docId:string, obj:any){
    return this.firestore.collection('mediaConversionJobs').doc(docId).set(obj);
  }

  getBatch(lastSeen: string){
    return this.firestore.collection("channels", ref=>ref.orderBy("name").startAfter(lastSeen)
    .limit(50))
    .snapshotChanges()
    .pipe(auditTime(400),take(1),
      tap(arr=>(arr.length ? null : this.endOfChannel=true)),
      map(arr=>{
        return arr?.reduce((acc,cur)=>{
          const id=cur.payload.doc.id
          const data= cur.payload.doc.data() as {}
          return { ...acc,[id]:{id,...data}}
        },{})
      })
    );
  }
  getChannelsByName(name: string){
    return this.firestore.collection('channels', ref=>ref.orderBy("name")
    .startAt(name.toUpperCase()).endAt(name.toLowerCase() +'\uf8ff'))
    .valueChanges({idField:"id"}).pipe(auditTime(1400))
  }

  getChannels(){
    return this.firestore.collection('channels', ref=>ref.orderBy("name"))
    .valueChanges({idField:"id"})
  }

  getEventsByBatch(lastSeen: string, channelDocId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("events", ref=>ref.orderBy("title").startAfter(lastSeen)
    .limit(50))
    .snapshotChanges()
    .pipe(auditTime(400),take(1),
      tap(arr=>(arr.length ? null : this.endOfEvent=true)),
      map(arr=>{
        return arr?.reduce((acc,cur)=>{
          const id=cur.payload.doc.id
          const data= cur.payload.doc.data() as {}
          return { ...acc,[id]:{id,...data}}
        },{})
      })
    );
  }
  getEventsByName(name: string, channelDocId: string){
    return this.firestore.collection('channels').doc(channelDocId).collection("events", ref=>ref.orderBy("title")
    .startAt(name.toUpperCase()).endAt(name.toLowerCase() +'\uf8ff'))
    .valueChanges({idField:"id"}).pipe(auditTime(1400))
  }

  async getChannelsByIdArray(values:string[]){
    const docID = firebase.firestore.FieldPath.documentId()
    return (
      await Promise.all(
        chunk(values,10).map(
          slice => {
            let query = this.firestore.collection("channels").ref.where(docID,"in",slice);
            return query.get();
          }
        )
      )
    ).reduce((acc:any, batchResult) => [...acc, ...batchResult.docs], []);
  };

  async getEventsByChannelId(channelDocId:string){
    return this.firestore.collection("channels").doc(channelDocId).collection("events").ref.get()
  }

  async getAllEvents(){
    return this.firestore.collectionGroup("events").get()
  }

  async getVideosByChannelId(channelDocId:string){
    return this.firestore.collection("channels").doc(channelDocId).collection("videos").ref.get()
  }

  async getEventsByIdArray(values:string[], channelDocId: string){
    const docID = firebase.firestore.FieldPath.documentId()
    return (
      await Promise.all(
        chunk(values,10).map(
          slice => {
            let query = this.firestore.collection("channels").doc(channelDocId).collection("events").ref.where(docID,"in",slice);
            return query.get();
          }
        )
      )
    ).reduce((acc:any, batchResult) => [...acc, ...batchResult.docs], []);
  };

  async getVideosByIdArray(values:string[], channelDocId: string){
    const docID = firebase.firestore.FieldPath.documentId()
    return (
      await Promise.all(
        chunk(values,10).map(
          slice => {
            let query = this.firestore.collection("channels").doc(channelDocId).collection("videos").ref.where(docID,"in",slice);
            return query.get();
          }
        )
      )
    ).reduce((acc:any, batchResult) => [...acc, ...batchResult.docs], []);
  };

  async getMediaConversionJobs(liveStreamDocIds: string[]){
      return (
        await Promise.all(
          chunk(liveStreamDocIds,10).map(
            slice => {
              let query = this.firestore.collection("mediaConversionJobs").ref.where("livestreamDocId","in",slice);
              return query.get();
            }
          )
        )
      ).reduce((acc:any, batchResult) => [...acc, ...batchResult.docs], []);
  }

  getMediaConversionDoc(liveStreamId: string){
    return this.firestore.collection('mediaConversionJobs',
    ref=> ref.where('livestreamDocId','==',liveStreamId)).valueChanges().pipe(auditTime(5000))
  }

  async getChannelById(channelId:string){
    const dataRef = await this.firestore.collection("channels").doc(channelId).ref.get()
    return dataRef
  }

  getMasterChannel(channelId:string){
    return this.firestore.collection('clubs', ref=> ref.where('channelDetails.channelId','==',channelId)).valueChanges({idField:"docId"})
  }

  async getPlanSource(docId:string){
    return this.firestore.collection("channels").doc(docId).collection("plans").ref.get()
  }
  async updateEvent(channelDocId:string, eventDocId:string, obj:any, brandingOverride: Partial<Branding>|undefined, bannerPath:string){
    const eventDoc = this.firestore.firestore.collection("channels").doc(channelDocId).collection("events").doc(eventDocId)

    return this.firestore.firestore.runTransaction(async tr => {
      const eventData = await tr.get(eventDoc);
      const eventDataToUpdate = eventData.data();
      if(!eventDataToUpdate) return
      // delete obj.posterImage
      // if(obj.pages && obj.pages[0].brandingOverride?.bannerImages) delete obj.pages[0].brandingOverride.bannerImages
      // if(obj.pages && brandingOverride && brandingOverride.bannerImages && eventDataToUpdate.pages[0].brandingOverride && eventDataToUpdate.pages[0].brandingOverride.bannerImages) set(obj, bannerPath, eventDataToUpdate.pages[0].brandingOverride.bannerImages)
      tr.update(eventDoc, obj)
    });

  }

  async updateNamedTagTransaction(channelDocId:string, tagDocId:string, obj:any, brandingOverride: Partial<Branding>|undefined, bannerPath:string){
    const tagDoc = this.firestore.firestore.collection("channels").doc(channelDocId).collection("tags").doc(tagDocId)
    return this.firestore.firestore.runTransaction(async tr => {
      const tagData = await tr.get(tagDoc);
      const tagDataToUpdate = tagData.data();
      if(!tagDataToUpdate) return 
      // if(obj.cover) delete obj.cover
      // if(obj.pages && obj.pages[0].brandingOverride?.bannerImages) delete obj.pages[0].brandingOverride.bannerImages
      // if(obj.pages && brandingOverride && brandingOverride.bannerImages && tagDataToUpdate.pages && tagDataToUpdate.pages[0].brandingOverride && tagDataToUpdate.pages[0].brandingOverride.bannerImages) set(obj, bannerPath, tagDataToUpdate.pages[0].brandingOverride.bannerImages)
      tr.update(tagDoc, obj)
    });

  }
  
}
