import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { chain, cloneDeep, isEqual, keyBy } from "lodash";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map, mergeMap, scan, takeUntil } from 'rxjs/operators';
import { BreadCrumbsService, ModuleHeader } from 'src/app/shared/bread-crumbs.service';
import { ClubRegistrationsService, InviteStatus } from "src/app/shared/club-registrations.service";
import { CountryCodeService, countryCode } from "src/app/shared/country-code.service";
import { TeamsService } from 'src/app/shared/teams.service';
import { Upload, UploadService } from "src/app/shared/upload.service";
import { AuthGuard, Role } from "src/app/shared/userSession.service";
import { ChannelType, ChannelsService, FilterInput, Watermark } from '../../shared/channels.service';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Channel } from "@yoimo/interfaces";
import firebase from 'firebase/compat/app';
import moment from "moment";
import { CancelPopupComponent } from "src/app/material-dialog/cancel-popup/cancel-popup.component";
import { DeletePopupComponent } from "src/app/material-dialog/delete-popup/delete-popup.component";
import { InternetLostComponent } from 'src/app/material-dialog/internet-lost/internet-lost.component';
import { SuccessPopupComponent } from "src/app/material-dialog/success-popup/success-popup.component";
import { FormrestoreService } from "src/app/shared/formrestore.service";
import { HomeService } from "src/app/shared/home.service";
import { MasterChannelService } from "src/app/shared/master-channel.service";
import { VideosService } from "src/app/shared/videos.service";
import { DialogService } from '../../shared/dialog.service';
import { ErrorModalComponent } from "../push-notification/error-modal.component";

const ACCEPTED_FILE_TYPES =[ 'image/png']
const ACCEPTED_FILE_SIZE = 1 * 1024 * 1024


declare var $:any
export enum Tabs{
  enabled,
  disabled,
  pending
}

export enum ChannelTabs{
  CLUBS,
  CHANNELS
}

export interface timeDropDown{
  showValue: string,
  value: moment.unitOfTime.DurationConstructor
}
@Component({
  selector: "app-channels-view",
  templateUrl: "./channels-view.component.html",
  styleUrls: ["./channels-view.component.scss"],
  animations:[
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight:'35px'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ChannelsViewComponent implements OnInit {
  dataList: any[]=[]
  dataSource :MatTableDataSource<any[]>
  displayedColumns: string[] =[]
  rowIndex: number =-1
  alphabets: string[]= ['All','A','B','C','D','E','F','G','H','I',
  'J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
  showLetters: boolean = false
  deviceType: string =''
  channelSubs: Subscription
  tabs: Tabs
  tab= Tabs
  channelTabs:ChannelTabs
  channelTab = ChannelTabs
  isNoRec: boolean= false
  timeDropDownOptions: timeDropDown[]=[
    {value:'days',showValue:"Days"},
    {value:'week',showValue:"Weeks"},
    {value:'month', showValue:"Months"},
    {value:'year',showValue:"Years"}]
  selectedTime: timeDropDown=this.timeDropDownOptions[0]
  channelTypeDropDownOptions: string[]=[ 'All', 'Attached Clubs','Unattached Clubs']
  channelCreatedFromDropDown:string[] = ['All','Backoffice','Sign-ups']
  channelTypeFilterApplied: boolean = false
  channelTypeDisplayed: string = 'All'
  isLoader: boolean=false
  filterInput: Partial<FilterInput>={
    clubName:'',
    country:{country:'',code:''}, 
    createdFrom:'All', 
    channelType:'All'
  }
  selectedInputValues: Partial<FilterInput>={
    clubName:'',
    country:{country:'',code:''}, 
    createdFrom:'All', 
    channelType:'All'
  }
  selectedCreatedFrom:string = 'All'
  selectedChannelType?:string = 'All'
  filteredName:string = '';
  liveOffset = new BehaviorSubject(null)
  channelLiveOffset = new BehaviorSubject(null)
  infinite!: Observable<any[]>;
  theEnd= false
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport
  collection: string=''
  countryList: countryCode[]
  selectedCountry: countryCode={country:'',code:''}
  // @ViewChild(MatSort) sort!: MatSort;
  // @ViewChild(MatSort, {static: false}) sort!: MatSort;
  @ViewChild('menuTypeTriggerBtn', { read: MatMenuTrigger, static: false}) menuTypeTriggerBtn!: MatMenuTrigger;
  @ViewChild('menuCountryTriggerBtn', { read: MatMenuTrigger, static: false}) menuCountryTriggerBtn!: MatMenuTrigger;
  @ViewChild('menuFromTriggerBtn', { read: MatMenuTrigger, static: false}) menuFromTriggerBtn!: MatMenuTrigger;


    @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if(this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }
  filterCountryText: string=''
  filterCountry: countryCode[]=[]
  userDocIds: any
  countryPlaceHolder: string=''
  isFilterApplied: boolean = false
  isArchived:boolean=false
  isPending:boolean=false
  // createdFrom:string = "All"
  createdFromFilterApplied:boolean=false
  private ngUnsubscribe = new Subject()
  scrollOffset:number = 0
  isSpinner:boolean=false
  checkInviteStatus!:Subscription
  isPanelOpen: boolean = false
  expandedElement: any|null;
  tagPrerollText:string = ""
  inputIndex:number = -1;
  @ViewChild('watermarkImg') watermarkImg!: ElementRef;
  uploadTask!: firebase.storage.UploadTask;
  upload!: Upload;
  watermarkImage: string = ''
  isPopupOpened: boolean = false
  currentTabName: 'Club' | 'Channel' = 'Club' 

  constructor(
    private channelsService: ChannelsService,
    private afs: AngularFirestore,
    public formRestoreService: FormrestoreService,
    private dialog: MatDialog,
    private breadCrumbsService: BreadCrumbsService,
    private teamService: TeamsService,
    private countryCodeService: CountryCodeService,
    private router: Router,
    private clubRegistrationService: ClubRegistrationsService,
    private userSession: AuthGuard,
    private dialogService: DialogService,
    private videoService: VideosService,
    private uploadService: UploadService,
    private masterChannelService: MasterChannelService,
    private homeService:HomeService) {
    this.dataSource = new MatTableDataSource(this.dataList)
    this.tabs= this.channelsService.tabId
    this.channelSubs= new Subscription()
    this.filterInput= this.channelsService.filterInput
    this.selectedInputValues = cloneDeep(this.channelsService.filterInput)
    this.teamService.filterMenu={showTime:false, showTeamName:false,showUser:false, showCreatedBy:false, showCreatedName: true}
    this.teamService.filterInput={timeValue: 0,teamName:'',userId:'', adminId:''}
    this.teamService.tabId= this.teamService.tabIds.HOME
    this.filterCountry=this.countryList=this.countryCodeService.countryCodeMap
    this.selectedCountry= this.filterInput.country || {country:'',code:''}
    this.selectedTime = this.channelsService.selectedTime || this.timeDropDownOptions[0]
    this.videoService.selected = {startDate: moment().subtract(6, 'days'), endDate: moment()}
    this.videoService.categoryTabId = this.videoService.categoryTabIds.STREAM
    this.videoService.searchText = ''
    this.teamService.masterChannelTabId = this.teamService.masterChannelTabIds.HOME
    this.masterChannelService.tagsSearchText = ''
    this.masterChannelService.eventSearchText = ''
    this.masterChannelService.pagesSearchText = ''
    this.masterChannelService.attachedChannelSearchText = ''
    this.masterChannelService.rowId = ''
    this.videoService.statusTabId = this.videoService.statusTabIds.SCHEDULED
    this.channelTabs = this.channelsService.channelTabId
    this.currentTabName = this.channelsService.channelTabId == ChannelTabs.CHANNELS ? 'Channel' : 'Club'
    this.channelsService.shareBoolean
  }

  async ngOnInit() {
    this.formRestoreService.streamTabId=this.formRestoreService.streamTabIds.SCHEDULED
    this.breadCrumbsService.setBreadCrumbs({name: ModuleHeader.CHANNELS})
        this.displayedColumns = this.channelTabs == ChannelTabs.CHANNELS ? ["channelName","description","country",'website'] :( this.tabs !== Tabs.pending ? this.tabs === Tabs.enabled ?["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate", "channelType"]
    : ["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate"]
    : ["channelName","description","exclusive","country","sport","crmId", "createdDate"])
    this.isLoader=true
    $(document).ready(()=>{
      let ua = navigator.userAgent;
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        this.deviceType = 'mobile';
        this.countryPlaceHolder = 'Choose'
      } else {
        this.deviceType = 'web';
        this.countryPlaceHolder = 'Choose Country'
      }
    })
    this.channelTabs == ChannelTabs.CHANNELS ? this.getLiveChannelsData() : this.getLiveClubsData(this.tabs)
    this.channelsService.resetChannelInfo()
    this.homeService.resetBGWhite()
  }

  showResetBtn(){
    if(this.channelTabs == 0){
      if(this.tabs == 0){
        return ((this.formRestoreService.isChannelFilter && this.detectChanges()) && (this.filterInput.clubName || this.filterInput.createdFrom !== 'All' || this.filterInput.channelType !== 'All' || this.filterInput.country?.country))
      }
      else if(this.tabs == 1){
        return ((this.formRestoreService.isChannelFilter && this.detectChanges()) && (this.filterInput.clubName || this.filterInput.createdFrom !== 'All' || this.filterInput.country?.country))
      }
      else{
        return ((this.formRestoreService.isChannelFilter && this.detectChanges()) && (this.filterInput.clubName || this.filterInput.country?.country))
      }
    }
    else{
      return ((this.formRestoreService.isChannelFilter && this.detectChanges()) && (this.filterInput.clubName || this.filterInput.country?.country))
    }
  }

  detectChanges(){
    let selectedValues:any = {
      clubName: this.selectedInputValues.clubName,
      country: cloneDeep(this.selectedInputValues.country)
    }
    let filteredValues:any = {
      clubName: this.filterInput.clubName,
      country: cloneDeep(this.filterInput.country)
    }
    if(this.channelTabs == 0 && this.tabs == 0){
      return (isEqual(this.selectedInputValues,this.filterInput))
    }
    else if ( this.channelTabs == 0 && this.tabs == 1){
      selectedValues = {
        ...selectedValues,
        createdFrom:this.selectedInputValues.createdFrom
      }
      filteredValues = {
        ...filteredValues,
        createdFrom:this.filterInput.createdFrom
      }
      return (isEqual(this.selectedInputValues,this.filterInput))
    }
    else{
      return (isEqual(cloneDeep(selectedValues),cloneDeep(filteredValues)))
    }
  }

  applyNameFilterOnEnter(){
    !this.detectChanges() && this.filterInput.clubName && this.applyFilter()
  }

  cancelFilter(){
    this.filterInput = cloneDeep(this.selectedInputValues)
    this.selectedInputValues = cloneDeep(this.filterInput)
  }

  onShowLetters(event: MouseEvent){
    this.showLetters= event.type === "mouseover"
  }

  getLiveClubsData(tabId: Tabs){
    this.isLoader=true
    this.collection= tabId === this.tab.disabled? 'archive': tabId === this.tab.enabled ? 'clubs': 'clubRegistrations'
    this.dataList=[]

    this.isPending = tabId === this.tab.pending;
    this.dataSource= new MatTableDataSource(this.dataList)
    if(this.collection=="archive") this.isArchived=true
    if(tabId !== this.tab.pending){
      const batchMap= this.liveOffset.pipe(
        mergeMap((n:any)=> this.channelsService.getBatch(this.collection,n)),
        scan((acc, batch)=>{
          return {...acc,...batch};
        },{})
      )
      this.infinite= batchMap.pipe(map(v=> Object.values(v)))
      this.channelSubs=this.infinite.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async result=>{
        await this.setDataList(result)
        if (this.tabs == Tabs.disabled && (this.filterInput.clubName || this.filterInput.country?.country || this.filterInput.createdFrom !== 'All')){
          this.formRestoreService.isChannelFilter && this.applyFilter()
          return
        }
        else if(this.tabs == Tabs.enabled &&( this.filterInput.clubName || this.filterInput.country?.country || this.filterInput.createdFrom !== 'All' || this.filterInput.channelType !== 'All')){
          this.formRestoreService.isChannelFilter && this.applyFilter()
          return
        }
      })
    }
    else{
      const newSubject = new BehaviorSubject<string[]>(['name'])
      this.infinite= newSubject.pipe(map(v=> Object.values(v)))
      this.clubRegistrationService.getClubsData().pipe(takeUntil(this.ngUnsubscribe)).subscribe(async result=>{
        await this.setDataList(result)
        if ( this.filterInput.clubName || this.filterInput.country?.country ){
          this.formRestoreService.isChannelFilter && this.applyFilter()
        }
      })
    }
    setTimeout(() => {
      if (!window.navigator.onLine){
        this.isLoader=false;
        this.openInternetLost();
      }
      this.isSpinner= false
    }, 1000)
    
  }

  getLiveChannelsData(){
    this.isLoader=true
    this.collection = 'channels'
    this.dataList=[]
    // this.dataSource.sort = null
    this.dataSource= new MatTableDataSource(this.dataList)
    const batchMap= this.channelLiveOffset.pipe(
      mergeMap((n:any)=> this.channelsService.getBatch(this.collection,n)),
      scan((acc, batch)=>{
        return {...acc,...batch};
      },{})
    )
    this.infinite= batchMap.pipe(map(v=> Object.values(v)))
    this.channelSubs=this.infinite.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async result=>{
      await this.setChannelDataList(result)
      this.formRestoreService.isChannelFilter && (this.filterInput.clubName || this.filterInput.country?.country) && this.applyFilter()
    })
    setTimeout(() => {
      if (!window.navigator.onLine){
        this.isLoader=false;
        this.openInternetLost();
      }
      this.isSpinner= false
    }, 1000)
    
    // this.getLiveClubsData(this.tab.enabled)
  }

  setTime(timeOption: timeDropDown){
    this.selectedTime= timeOption
  }

  isNumberKey(evt: any){
    const charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && ((charCode < 48 || charCode > 57) || (charCode >= 96 && charCode <= 105)))
        return false;
    return true;
  }

  selectChannelType(channelType: string){
    this.filterInput.channelType = channelType
    this.menuTypeTriggerBtn.closeMenu()
  }

  openCreatedFromMenu(){
    if(!this.isLoader) this.menuFromTriggerBtn.openMenu()
  }

  openCountryMenu(){
    if(!this.isLoader) this.menuCountryTriggerBtn.openMenu()
  }

  openCreatedTypeMenu(){
    if(!this.isLoader) this.menuTypeTriggerBtn.openMenu()
  }

  selectChannelCreatedFrom(channelCreatedFrom:any){
    this.filterInput.createdFrom = channelCreatedFrom 
    this.menuFromTriggerBtn.closeMenu()
  }

  onCreateFromEnter(event:any){
    this.applyFilter()
    // this.menuFromTriggerBtn.closeMenu()
  }

  onCreateTypeEnter(event:any){
    this.applyFilter()
    // this.menuTypeTriggerBtn.closeMenu()
  }

  onCountryEnter(event:any){
    this.applyFilter()
    // this.menuCountryTriggerBtn.closeMenu()
  }

  async applyFilter(){  
    if (!window.navigator.onLine){
      this.isLoader=false;
      this.openInternetLost();
      return
    }

    this.selectedInputValues = cloneDeep(this.filterInput)
    this.channelSubs.unsubscribe()
    this.formRestoreService.isChannelFilter=true
    this.isNoRec=false
    this.channelsService.filterInput=this.filterInput
    this.isLoader=true
    this.dataList=[]
    this.filterCountryText = ''
    this.isFilterApplied = true
    this.channelsService.endOfClub= true


    if(this.filterInput.clubName){
      const filterSubs= this.channelsService.getClubByName(this.collection,this.filterInput.clubName).subscribe(async result=>{
        if(this.channelTabs == 0){
          await this.setDataList(result)
        }
        else{
          await this.setChannelDataList(result)
        }
        this.multipleFilters()
        filterSubs.unsubscribe() 
      })
    }
    
    else if(this.filterInput.country?.country){
      const filterSubs= this.channelsService.getClubByCountry(this.collection,this.filterInput.country?.code).subscribe(async result=>{
        if(this.channelTabs == 0){
          await this.setDataList(result)
        }
        else{
          await this.setChannelDataList(result)
        }
        this.multipleFilters()
        filterSubs.unsubscribe() 
      })
    }

    else if(this.filterInput.createdFrom !== 'All'){
      const filterSubs = this.channelsService.getClubByCreated(this.collection).subscribe(async result=>{
        if(this.channelTabs == 0){
          await this.setDataList(result)
        }
        else{
          await this.setChannelDataList(result)
        }
        this.multipleFilters()
        filterSubs.unsubscribe()
      })
    }

    else if(this.filterInput.channelType && this.channelTabs != 1 && this.tabs === 0 ){
      if(this.filterInput.channelType == 'Unattached Clubs' && this.tabs === 0){
        this.displayedColumns =["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate"]
        const filterSubs = this.channelsService.getClubsDataAll(this.collection).subscribe(async result=>{
          if(this.channelTabs == 0){
            await this.setDataList(result)
          }
          else{
            await this.setChannelDataList(result)
          }
          this.multipleFilters()
          filterSubs.unsubscribe()
        })
      }

      else if(this.filterInput.channelType == 'Attached Clubs' && this.tabs === 0){
        this.displayedColumns =["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate"]
        const filterSubs = this.channelsService.getClubByType(this.collection).subscribe(async result=>{
          if(this.channelTabs == 0){
            await this.setDataList(result)
          }
          else{
            await this.setChannelDataList(result)
          }
          this.multipleFilters()
          filterSubs.unsubscribe()
        })
      }

      else if(this.filterInput.channelType == 'All' || this.tabs != 0){
        this.displayedColumns = this.filterInput.channelType == 'All' ? 
            ["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate","channelType"]
            :["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate"]

       
        const filterSubs = this.channelsService.getClubsDataAll(this.collection).subscribe(async result=>{
          if(this.channelTabs == 0){
            await this.setDataList(result)
          }
          else{
            await this.setChannelDataList(result)
          }
          this.multipleFilters()
          filterSubs.unsubscribe()
        })
      }

    }
    
    else{
      this.channelTabs == 1 ? this.getLiveChannelsData() : this.getLiveClubsData(this.tabs)
    }
   
  }

  isDefaultFilterState() {
    if(this.channelTabs == ChannelTabs.CHANNELS ){
      return this.filteredName=='' && !this.filterInput.country?.country && this.filterInput.timeValue==0
    }else{
    if(this.tabs === Tabs.disabled || this.tabs === Tabs.pending)
      return this.filteredName=='' && !this.filterInput.country?.country && this.filterInput.timeValue==0 && (this.filterInput.createdFrom==="All")
    return !this.filterInput.country?.country && this.selectedCreatedFrom=="All" && this.selectedChannelType=="All" && this.filteredName==='';
    }
  }

  clearFilter(){
    this.cancelFilter()
    this.isNoRec=false
    this.channelsService.filterInput=this.filterInput={
      clubName:'',
      country:{country:'',code:''},
      createdFrom:"All", 
      channelType:'All'
    }
    this.selectedInputValues = cloneDeep(this.filterInput)
    this.filterCountryText=''
    this.channelTypeFilterApplied = false
    this.onChannelTabChanged(this.channelTabs == ChannelTabs.CLUBS ? 0 : 1)
    // this.channelTabs == ChannelTabs.CLUBS && this.onTabChanged(this.tabs === Tabs.enabled ? 0: this.tabs === Tabs.disabled ? 1 : 2)
  }

  removeCountryFilter(){
   this.filterInput.country = {
    country : '',
    code : ''
   }
  }

  removeClubName(){
    this.filterInput.clubName = ""
  }

  nextBatch(event: number, offset: any){
    if(this.channelTabs == 1) {
      this.nextChannelBatch(event,offset)
      return
    }
    if( this.channelsService.endOfClub || this.tabs === this.tab.pending ) return
    const scrollOff = this.viewport.measureScrollOffset("top")
    if(scrollOff > this.scrollOffset) {
      this.scrollOffset = scrollOff
      this.liveOffset.next(offset)
      this.isSpinner= true
    }
  }

  nextChannelBatch(event: number, offset: any){
    if( this.channelsService.endOfClub) return
    const scrollOff = this.viewport.measureScrollOffset("top")
    if(scrollOff > this.scrollOffset) {
      this.scrollOffset = scrollOff
      this.channelLiveOffset.next(offset)
      this.isSpinner= true
    }
  }
  
  resetChannelSubscription(tab?: Tabs){
    this.channelSubs.unsubscribe()
    this.liveOffset.next(null)
    this.channelLiveOffset.next(null)
    // this.nextBatch(0,'')
    this.channelsService.endOfClub=false
    if(tab!==undefined){
      this.tabs = tab
      this.channelsService.tabId= tab
    }
    this.channelsService.channelTabId = this.channelTabs
    this.selectedInputValues = cloneDeep(this.filterInput)
    this.dataList=[]
    this.dataSource= new MatTableDataSource(this.dataList)
  }

  onChannelTabChanged(event:number){
    this.scrollOffset = 0
    switch(event){
      case 0:
        this.channelTabs = this.channelTab.CLUBS
        this.currentTabName = 'Club'
        this.resetChannelSubscription()
        this.isArchived = false
        this.isPending = false
        this.isSpinner = false
        this.isLoader = true
        this.displayedColumns = this.tabs === Tabs.enabled ? ["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate", "channelType"]
              : this.tabs === Tabs.disabled ? ["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate"]
              : ["channelName","description","exclusive","country","sport","crmId", "createdDate"]        
        this.getLiveClubsData(this.tabs)
            break;
      case 1:
        this.channelTabs = this.channelTab.CHANNELS
        this.currentTabName = 'Channel'
        this.resetChannelSubscription()
        this.isArchived = false
        this.isPending = false
        this.isSpinner = false
        this.isLoader = true
        this.displayedColumns = ["channelName","description","country",'website']
        this.getLiveChannelsData()
        break;
    }
  }

  onTabChanged(event: number){
    this.scrollOffset = 0
    switch (event) {
      case 0:
        this.resetChannelSubscription(this.channelsService.tabIds.enabled)
        this.isArchived = false
        this.isPending = false
        this.isSpinner = false
        // this.displayedColumns = ["channelName","description","exclusive","country","email","lastLiveStream","lastTicketSold","lastActivity","createdDate"]
        this.displayedColumns = ["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate", "channelType"] 
        this.getLiveClubsData(this.tab.enabled)
        break;
      case 1:
        this.resetChannelSubscription(this.channelsService.tabIds.disabled)
        this.isArchived = true
        this.isPending = false
        this.isSpinner = false
        this.displayedColumns = ["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate"] 
        this.getLiveClubsData(this.tab.disabled)
        break;
      case 2:
        this.resetChannelSubscription(this.channelsService.tabIds.pending)
        this.isArchived = false
        this.isPending = true
        this.isSpinner = false
        this.displayedColumns =  ["channelName","description","exclusive","country","sport","crmId", "createdDate"]
        this.getLiveClubsData(this.tab.pending)
        break;
    }
  }
  async getEmail(clubIds: any){
    const subscriptionDocs= await this.channelsService.getUserDocId(clubIds)
    const userDocId= subscriptionDocs.map((item:any)=> ({
      clubId: item.data().clubDocId,
      userDocId: item.data().userDocId
    }))
    this.userDocIds= keyBy(userDocId,"clubId")
  }
  async setActiveOrArchivedList(result:any){
    const clubIds= result.map((item:any)=> item.id)
    await this.getEmail(clubIds)
    this.dataList= chain(result).map((item:any)=>{
      return({
        id: item.id,
        channelName: item?.name?.trim() || 'N/A',
        createdDate: moment(item?.createdTime?.toDate()).format("DD MMM YYYY HH:mm:ss"),
        createdFrom: item?.createdFrom ? 'BACKOFFICE' :  "ARENA",
        imageUrl: (item?.imageUrl == 'assets/club_default_logo.png' || !item?.imageUrl) ? 'assets/club_default_logo.png': item.imageUrl ,
        color: (item?.imageUrl == 'assets/club_default_logo.png' || !item?.imageUrl) ? 'black':'transparent',
        country: item?.country ? (this.countryCodeService.countryCodeMap.
          find((element:countryCode)=> element.code===item.country)?.country || 'N/A') : 'N/A',
        lastActivity: item.lastLiveStream? moment(item.lastLiveStream.toDate()).format("DD MMM YYYY") : 'N/A',
        lastTicketSold: item.lastTicketSold? moment(item.lastTicketSold.toDate()).format("DD MMM YYYY") : 'N/A',
        lastLiveStream: item.lastLiveStream? moment(item.lastLiveStream.toDate()).format("DD MMM YYYY") : 'N/A',
        email: item.subscriptionId? (item.users[this.userDocIds[item.id]?.userDocId]?.email || 'N/A')  :'N/A',
        exclusive: item.isExclusive ? "Yes": "No",
        description: item.description?.trim() || 'N/A',
        channelId: item.channelDetails?.channelId || '',
        isMasterChannel: !!item.channelDetails?.isMasterChannel,
        defaultTags: item.channelDetails?.defaultTags || [],
        hasChannelExperience:  item.channelDetails?.channelId ? true : false ,
        setAdPreroll: item.defaultAdCuePoints && item.defaultAdCuePoints.find((element:any)=> element.time == "START") ? true : false,
        defaultAdCuePoints: item.defaultAdCuePoints?.find((element:any)=> element.time == "START") || {},
        showCopy: false,
        channelType: item.channelDetails?.channelId ? 'Attached Club' : 'Unattached Club',
        watermark: {enabled:false, imageUrl:''},
        watermarkLoader: true,
        masterChannelName: '',
        countryCode: item.country,
      })
    }).sortBy(['channelName']).value()
    this.setDataSource()
    return
  }

  async setDataList(result: any){
    if(this.tabs !== this.tab.pending){
      await this.setActiveOrArchivedList(result)
      return
    }
    this.dataList= chain(result).filter(item=> item.status === InviteStatus.INVITE_SENT && !item?.archived).map((item:any)=>({
      id: item.id,
      channelName: item?.name.trim() || 'N/A',
      imageUrl: item?.logoUrl ||  'assets/club_default_logo.png',
      inviteStatus: item.status,
      color: item?.logoUrl ? 'transparent':'black',
      country: item?.country ? (this.countryCodeService.countryCodeMap.
        find((element:countryCode)=> element.code===item.country)?.country || 'N/A') : 'N/A',
      sport: item?.sport.name || 'N/A',
      crmId: item?.crmId || 'N/A',
      exclusive: item.isExclusive ? "Yes": "No",
      description: item.description?.trim() || 'N/A',
      createdDate: item.createdTime ? moment(item.createdTime.toDate()).format("DD MMM YYYY HH:mm:ss") : 'N/A',
      watermark: {enabled:false, imageUrl:''},
      watermarkLoader: true,
      adminEmails: item.adminEmails || [],
  })).sortBy(['channelName']).value()
    this.setDataSource()
  }

  async setChannelDataList(result:any){
    this.dataList = chain(result).filter(item => !item?.archived).map( (item: any) => ({
      id: item.id,
      channelName: item?.name.trim() || 'N/A',
      country: item?.country ? (this.countryCodeService.countryCodeMap.
        find((element: countryCode) => element.code === item.country)?.country || 'N/A') : 'N/A',
      description: item.description?.trim() || 'N/A',
      imageUrl: item?.branding?.logoImage || 'assets/club_default_logo.png',
      color: (item?.branding?.logoImage && item?.branding?.logoImage!=='assets/club_default_logo.png') ? 'transparent':'black',
      website: item.domain ? `https://${item?.domain}/${item.slug}` : 'N/A',
      // lastLiveStream: await this.channelsService.getLastLiveStream(item.id) !== 'N/A' ? moment(await this.channelsService.getLastLiveStream(item.id) as Moment).format("DD MMM YYYY") : 'N/A',
    })).sortBy(['channelName']).value()
    this.setDataSource()
  }
  
  multipleFilters(){
    // const currentTab = this.channelTabs
    let selectedFrom = 'All'
    if(this.filterInput.createdFrom == 'Backoffice'){
      selectedFrom = 'BACKOFFICE'
    } 
    else if(this.filterInput.createdFrom == 'Sign-ups'){
      selectedFrom = 'ARENA'
    }
    this.dataSource.filterPredicate = function(data: any, filter: any): boolean {
      const name = !filter.channelName || data.channelName.toLowerCase().includes(filter.channelName.toLowerCase())
      const country = !filter.country || data.country.toLowerCase().includes(filter.country.toLowerCase())
      const created = !filter.isCreatedFrom || (filter.createdFrom!="All" && data.createdFrom==selectedFrom) || (filter.createdFrom=="All" && data)
      const type =   !filter.isChannelType || (filter.channelType =='Attached Clubs' && data.channelType == 'Attached Club') || (filter.channelType =='Unattached Clubs' && !data.hasChannelExperience) || (filter.channelType == 'All' && data) 
      return name && country && created && type
    };
    const filter= { channelName: this.filterInput.clubName,
      country: this.filterInput.country?.country, 
      createdFrom: this.channelTabs == 0 && this.filterInput.createdFrom,
      isCreatedFrom: this.canCreateFromFilterApply(),
      isChannelType: this.canCreateTypeFilterApply(),
      channelType: this.filterInput.channelType
    }
    if(this.tabs === 0 && this.channelTabs === 0 && this.filterInput.channelType !== 'All'){ 
      this.channelTypeFilterApplied = true
    }
    else{
      this.channelTypeFilterApplied = false
    }
    this.tabs === Tabs.enabled && this.filterInput.channelType == 'All' ? 
    this.displayedColumns = ["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate", "channelType"]
    : this.displayedColumns = ["channelName","description","exclusive","country","lastLiveStream","lastTicketSold","lastActivity","createdDate"]

    if(this.tabs === Tabs.pending)this.displayedColumns = ["channelName","description","exclusive","country","sport","crmId", "createdDate"];
    if(this.channelTabs == 1){
      this.displayedColumns = ["channelName","description","country","website"]
    }
    this.dataSource.filter= filter as unknown as string
    this.dataSource.data= this.dataSource.filteredData
    this.isNoRec= this.dataSource.data.length === 0
  }
  setCountry(country: countryCode){
    this.filterInput.country = country
    this.menuCountryTriggerBtn.closeMenu()
  }
  countrySearch(){
    this.filterCountry= this.countryList.filter(item=>{
      return item.country.toLowerCase().indexOf(this.filterCountryText.toLowerCase()) != -1
    })
  }
  hideCountry(country: countryCode){
    return ((country.country)
    .toString()
    .toLowerCase()
    .indexOf(this.filterCountryText.toLowerCase())==-1 && this.filterCountryText)
  }

  openInternetLost(){
    const internetLost = this.dialog.open(InternetLostComponent, {
      width: '500px',
      panelClass: 'internet-lost',
      position: {
        'top':'180px',
      },
      // disableClose: true
    });
    return internetLost.afterClosed()
  }

  editChannel(event:MouseEvent,clubDocId:string, isMasterChannel: boolean)
  {
    event.stopPropagation();
    // this.channelsService.editDocId=clubDocId
    // this.channelsService.isEdit=true
    const url = isMasterChannel ? '/home/channels/edit-new-channel/' : 'home/channels/edit-club/'
    this.router.navigate([`${url}`+clubDocId])
  }

  setRowPointer(){
    return !(this.channelTabs === 0 && this.tabs === Tabs.pending)
  }

  canCreateTypeFilterApply(){
    return (this.tabs == Tabs.enabled && this.channelTabs == ChannelTabs.CLUBS)
  }

  canCreateFromFilterApply(){
    return ((this.tabs == Tabs.disabled || this.tabs == Tabs.enabled) && this.channelTabs == ChannelTabs.CLUBS)
  }

  isActiveTab(){
    return !(this.tabs === Tabs.disabled || this.tabs === Tabs.pending)
  }

  isChannelTab(){
    return (this.channelTabs === ChannelTabs.CHANNELS || this.tabs == Tabs.pending)
  }

  setDataSource(){
    this.dataSource= new MatTableDataSource(this.dataList)
    this.dataSource.sortingDataAccessor = (item:any, property) => {
    switch (property) {
      case 'createdDate': return item.createdDate!=='N/A' && new Date(item.createdDate);
      case 'lastActivity': return item.lastActivity!=='N/A' && new Date(item.lastActivity)
      case 'lastLiveStream': return item.lastLiveStream!=='N/A' && new Date(item.lastLiveStream)
      case 'lastTicketSold': return item.lastTicketSold!=='N/A' && new Date(item.lastTicketSold)
      default: return item[property];
    }
   }
    this.isLoader=false
    this.isSpinner=false
    this.isNoRec= this.dataSource.data.length === 0
  }

  navigateToChannelInfo(rowId: string){
    this.setRowPointer() && this.accessToSalesRole() && this.router.navigate([this.channelTabs == 0 ? `/home/channels/clubs/${rowId}` : `/home/channels/${rowId}` ])
  }

  navigateToCreateChannelOrClub(){
    this.router.navigate([this.channelTabs == 0 ? `/home/channels/create-club` : `/home/channels/create-channel` ])
  }

  deleteInvite(invite:any){
    const data = {
      header:"Delete Invite",
      message:"Are you sure you want to delete this club? This action cannot be undone.",
      buttonText: "Delete",
      imgSrc: "assets/delete.png"
    }
    this.deleteOrUpdateDialog(data).afterClosed().subscribe(res => {
      if(res){
        if (!window.navigator.onLine){
          this.openInternetLost();
          return
        }
        this.isSpinner=true
        this.checkInviteStatus = this.clubRegistrationService.getClubById(invite.id).subscribe((res:any) => {
          if(res.status !="INVITE_ACCEPTED"){
            this.clubRegistrationService.deleteInvite(invite.id).then(res => {
              this.isSpinner=false
              this.openSuccessPopup("Successfully deleted the Invite").afterClosed().subscribe(res => {
                this.onTabChanged(2)
              })
            })
          }else{
            this.showErrorComponent()
          }
          this.checkInviteStatus.unsubscribe()
        })
      }
    })
  }

  showErrorComponent(){
    this.isSpinner = false
    const cancelPopupComponent = this.dialog.open(CancelPopupComponent, {
      data: {cancelMessage: "channelPendingState"},
      panelClass: 'cancel-action',
      width: '468px',
      position: {
        'top':'150px'
      },
      disableClose: true
    });

    cancelPopupComponent.afterClosed().subscribe(res => {
      this.onTabChanged(2)
    })
    
  }

  openSuccessPopup(message:string){
    this.isSpinner=false
    const successDialog =  this.dialog.open(SuccessPopupComponent,{
      data:{
        successMessage:message
      },
      width:'468px',
      position:{
        'top':'13px'
      },
      disableClose:true,
      panelClass:"success-message-dialog"
    })

    return successDialog
  }

  openUpdatePopup(channelDocId: string, status: boolean, defaultAdCuePoints: any){
    let index = this.dataList.findIndex(item => item.id==channelDocId)

    if (!window.navigator.onLine){
      this.dataList[index].setAdPreroll = !status
      this.openInternetLost().subscribe(()=>{
        this.dataList[index].setAdPreroll = status
      });
      return
    }
    this.dataList[index].setAdPreroll = !status

    const popupInfo = status ? {
      header: 'Disable Preroll',
      message: 'Are you sure you want to disable a preroll Ad for this club?',
      buttonText: 'Disable',

    }:
    {
      header: 'Enable Preroll',
      message: 'Are you sure you want to enable a preroll Ad for this club?',
      buttonText: 'Enable',
      popUpType: true
    }
    this.deleteOrUpdateDialog(popupInfo).afterClosed().subscribe(res => {
      if(res){
        this.isSpinner= true
        return
      }
      index = this.dataList.findIndex(item => item.id==channelDocId)
      this.dataList[index].setAdPreroll = status
      return
    })
  }

  accessToSalesRole(){
    return !(this.tabs === Tabs.disabled && this.isRoleSales())
  }
  
  isRoleSales(){
    return this.userSession.userRole === Role.SALES
  }

  showKebab(){
    return (this.isArchived == true && !this.isRoleSales()) || this.tabs === this.tab.enabled
    || this.tabs === this.tab.pending
  }

  displayExpandedRow(close:boolean, clubId?:string, newChannel?:boolean){
    if(close) {
      this.isPanelOpen=false;
      return
    }
    const index = this.dataList.findIndex(club => club.id === clubId)
    const clubData = this.dataList[index]
    // this.fetchWatermark(clubId, index)

    if(clubId && newChannel){
      this.getMasterChannel(clubId)
    }
    this.isPanelOpen = true
    setTimeout(() => {
      document
        .getElementById("expanded-audience-div")
        ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }, 225);
  }

  async fetchWatermark(clubId:string|undefined, index:number){
    if(!clubId){
      this.dataList[index].watermarkLoader=false
      return
    }
    const watermark = await this.channelsService.getWatermark(clubId)
    if(watermark.data()){
      this.dataList[index].watermark = watermark.data() as Watermark
    }
    this.dataList[index].watermarkLoader=false
  }

  async getMasterChannel(clubId:string){
    const index = this.dataList.findIndex(club => club.id === clubId)
    if(this.dataList[index].masterChannelName) return
    const clubData = this.dataList[index]
    const channelId=clubData.channelId
    const masterClub:any = (await this.masterChannelService.getChannelById(channelId))
    const masterChannelData = { id:masterClub.id, ...masterClub.data()}
    if(masterChannelData?.name){
      this.dataList[index].masterChannelId = masterChannelData.id
      this.dataList[index].masterChannelName = masterChannelData.name
    }
  }

  openNewChannelDialog(typeofChannel: boolean, clubDocId: string, newChannelId: string, hasChannelExperience: boolean, channel: any){
    let index = this.dataList.findIndex(item => item.id==clubDocId)
    if (!window.navigator.onLine){
      this.dataList[index].hasChannelExperience = !hasChannelExperience 
      this.openInternetLost().subscribe(()=>{
        this.dataList[index].hasChannelExperience = hasChannelExperience
      });
      return
    }
    const id = `${clubDocId}-newChannel`
    const clientRect = document.getElementById(id)?.getBoundingClientRect() as DOMRect
    const top = clientRect.top < window.innerHeight/2 ? clientRect.top : clientRect.top - 260
    const dimensions = {top: `${top}px`, left:`${clientRect.left - 230}px`}
    this.isPopupOpened = true
    this.dataList[index].hasChannelExperience = !hasChannelExperience
    this.dialogService.openDialog(true, dimensions).afterClosed().subscribe((res)=>{
      if(!res && !newChannelId){
        this.dataList[index].hasChannelExperience = hasChannelExperience
        this.isPopupOpened=false
      }
      if(res && res.typeofChannel=== ChannelType.NEW_CHANNEL){
        // this.router.navigate(['/home/channels/edit-new-channel/'+clubDocId])
        this.setNewMasterChannel(channel, res, index)
      }
      if(res && res.typeofChannel === ChannelType.ATTACH_CHANNEL){
        this.isSpinner = true
        const attachChannelInfo: any={
          channelId: res.channelId,
          clubDocId,
          channelCreationMode: "ATTACH_EXISTING"
        }
        this.channelsService.createNewChannel(attachChannelInfo).then(result=>{
          this.isSpinner = false
          this.openSuccessPopup("Successfully attached this club")
          this.isPopupOpened = false
          this.dataList[index].masterChannelName = res.channelName
          const masterClub = this.masterChannelService.getMasterChannel(res.channelId).subscribe((res:any[]) => {
            const clubDetail = res.filter(club => club.channelDetails.isMasterChannel)[0]
            this.dataList[index].masterChannelId = clubDetail.docId
            masterClub.unsubscribe()
          })
        }).catch(error => {
        })
      }
    }

    )
  }
  
  setIndex(index:number, defaultAdCuePoints:any){
    this.inputIndex = index;
    this.tagPrerollText = defaultAdCuePoints.tag?.trim() || ""
  }

  showInput(index:number){
    return index==this.inputIndex;
  }

  removeIndex(docId:string, defaultAdCuePoints:any){
    this.inputIndex = -1
    let index = this.dataList.findIndex(item => item.id==docId)
    const newTag = defaultAdCuePoints.tag?.trim() || ""
    if(newTag == this.tagPrerollText.trim()){
      this.tagPrerollText = ""
      if(!this.dataList[index].defaultAdCuePoints.tag?.trim()){
        delete this.dataList[index].defaultAdCuePoints.tag
      }else{
        this.dataList[index].defaultAdCuePoints.tag = defaultAdCuePoints.tag.trim();
      }
      return
    }
    let oldObj = {}
    let newObj = {}
    if(defaultAdCuePoints.tag?.trim() && this.tagPrerollText == ""){
      oldObj = {
        time: defaultAdCuePoints.time,
        cueId: defaultAdCuePoints.cueId
      }
      newObj = {
        time: defaultAdCuePoints.time,
        cueId: defaultAdCuePoints.cueId,
        tag: defaultAdCuePoints.tag.trim()
      }
      this.dataList[index].defaultAdCuePoints.tag = defaultAdCuePoints.tag.trim();
    }else if(defaultAdCuePoints.tag?.trim() == ""){
      oldObj = {
        time: defaultAdCuePoints.time,
        cueId: defaultAdCuePoints.cueId,
        tag: this.tagPrerollText.trim()
      }
      newObj = {
        time: defaultAdCuePoints.time,
        cueId: defaultAdCuePoints.cueId
      }
      delete this.dataList[index].defaultAdCuePoints.tag
    }
    else{
      oldObj = {
        time: defaultAdCuePoints.time,
        cueId: defaultAdCuePoints.cueId,
        tag: this.tagPrerollText.trim()
      }
      newObj = {
        time: defaultAdCuePoints.time,
        cueId: defaultAdCuePoints.cueId,
        tag: defaultAdCuePoints.tag.trim()
      }
      this.dataList[index].defaultAdCuePoints.tag = defaultAdCuePoints.tag.trim();
    }

    this.isSpinner=true
    this.channelsService.manageCueTag(docId, oldObj, newObj).then(res => {
      this.isSpinner=false
    })
  }

  copyTag(defaultAdCuePoints:any, channelDocId:string){
    let index = this.dataList.findIndex(item => item.id==channelDocId)
    let link = document.createElement('textarea');
    link.value = defaultAdCuePoints.tag
    document.body.appendChild(link);
    link.focus();
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);
    this.dataList[index].showCopy = true;
    setTimeout(()=> {
      this.dataList[index].showCopy = false;
    },2000) 
  }

  async imageChangeEvent(event: any, channelDocId: string) {
    const selectedFile = event.target.files;
    if (
      selectedFile &&
      (selectedFile.item(0).size > ACCEPTED_FILE_SIZE ||
        !ACCEPTED_FILE_TYPES.includes(selectedFile.item(0).type))
    ) {
      this.openError('1 MB');
      this.watermarkImg.nativeElement.value =  null
      return;
    }
    this.getHeightandWidth(selectedFile,
      (value:any,selectedFile:any) =>{
        this.setBannerImage(value,selectedFile,channelDocId)
      })
    
  }

  async fileReader(event: any): Promise<string>{
    return new Promise((resolve)=>{
      const reader = new FileReader();
      reader.onload = (event: any) => resolve(event.target.result);
      reader.readAsDataURL(event[0]);
    })
  }

  openConformationPopup(file: FileList | null, channelDocId: string, image: string) {
    const data = {
      header: 'Upload Watermark',
      message: 'Are you sure you want to upload this picture as watermark for this club?',
      buttonText: 'Yes',
      popUpType: true
    }
    this.deleteOrUpdateDialog(data).afterClosed().subscribe((res) => {
      if (!res) {
        this.watermarkImg.nativeElement.value = null;
        return;
      }
      this.uploadCoverImage(file, channelDocId, image);
    });
  }

  uploadCoverImage(file: FileList | null, channelDocId: string, image: string) {
    if (!window.navigator.onLine) {
      this.openInternetLost();
      return;
    }
    this.isSpinner = true
    this.upload = new Upload(image, true);
    this.upload.name = `${channelDocId}.${file?.item(0)?.type.slice(6)}`;
    this.upload.metadata = {
      contentType: file?.item(0)?.type,
    };
    this.uploadService
      .pushUpload(
        this.upload,
        `channels/${channelDocId}/watermark`
      )
      .then(async (res) => {
        const url = await res.ref.getDownloadURL();
        const obj: Watermark = {
          enabled: false,
          imageUrl: url //.substring(0, url.indexOf('&'))
        };
        // this.channelsService.updateChannel({watermark: obj}, channelDocId).then(()=>{
        //   let index = this.dataList.findIndex(item => item.id==channelDocId)
        //   this.dataList[index].watermark = obj
        // })
        this.channelsService.updateWatermark(channelDocId, obj).then(()=>{
          let index = this.dataList.findIndex(item => item.id==channelDocId)
          this.dataList[index].watermark = obj
        })
        this.openSuccessPopup("Successfully uploaded image for watermark");
      })
      .catch((err) => {
      });
    setTimeout(() => {
      if (!window.navigator.onLine) {
        this.openInternetLost();
      }
    }, 15000);
  }

  openUpdateWatermarkPopup(channelDocId: string, watermarkStatus: boolean){
    let index = this.dataList.findIndex(item => item.id==channelDocId)
    if (!window.navigator.onLine){
      this.dataList[index].watermark.enabled = !watermarkStatus
      this.openInternetLost().subscribe(()=>{
        this.dataList[index].watermark.enabled = watermarkStatus
      });
      return
    }

    const popupInfo = watermarkStatus ? {
      header: 'Disable Watermark',
      message: 'Are you sure you want to disable watermark for this club?',
      buttonText: 'Disable',

    }:
    {
      header: 'Enable Watermark',
      message: 'Are you sure you want to enable watermark for this club?',
      buttonText: 'Enable',
      popUpType: true
    }
    this.dataList[index].watermark.enabled = !watermarkStatus
    this.deleteOrUpdateDialog(popupInfo).afterClosed().subscribe(res => {
      if(res){
        this.isSpinner= true
        this.channelsService.updateWatermark( channelDocId, this.dataList[index].watermark).then(()=>{
          this.isSpinner = false
        })
        return
      }
      index = this.dataList.findIndex(item => item.id==channelDocId)
      this.dataList[index].watermark.enabled = watermarkStatus
      return
    })
  }

  deleteOrUpdateDialog(data: Record<string,string|boolean|undefined>){
    return this.dialog.open(DeletePopupComponent,{
      data:data,
      panelClass:"delete-popup",
      width:'438px',
      position:{
        'top':'150px'
      },
      disableClose:true
    })
  }

  getHeightandWidth(selectedImage:any,callback:any){
    const img = document.createElement('img');
    const blob = URL.createObjectURL(selectedImage.item(0));
    img.src = blob;
    img.height
    img.onload = () => {
      const w = img.width;
      const h = img.height;
      callback({width:w, height:h},selectedImage)
    }
  }

  async setBannerImage(value:any, selectedFile:any, channelDocId: string){
    if(value.width!=1280 || value.height!=720){
      this.openError('1 MB',"resolution")
      this.watermarkImg.nativeElement.value = null
      return
    }
    this.watermarkImage = await this.fileReader(selectedFile)
    this.openConformationPopup(selectedFile, channelDocId, this.watermarkImage)

  }

  openError(size:string,resolutionError?:String){
    let message:string = ""
    if(resolutionError)
      message = "The preferred dimensions are 1280x720"
    const errorDialog = this.dialog.open(ErrorModalComponent,{
      width:'500px',
      position:{
        'top':'150px'
      },
      panelClass:'error-dialog',
      disableClose:true,
      data:{
        fileSize: size,
        message: message,
        format: '.png'
      }
    });
  }

  resendActivation(clubRegistrationId: string){
    this.clubRegistrationService.resendActivationEmail(clubRegistrationId)
    const successDialog = this.dialog.open(SuccessPopupComponent,{
      data:{
        successMessage:"Invite resent successfully"
      },
      width:'468px',
      position:{
        'top':'13px'
      },
      disableClose:true,
      panelClass:"success-message-dialog"
    })
  }

  setNewMasterChannel(channel: any, dialogResult: any, index: number){
    this.isSpinner = true
    const newChannelId = this.afs.createId()
    let newChannelData:Channel = {
      name: channel.channelName.trim(),
      description: channel.description === 'N/A'? '' : channel.description ,
      country: channel.countryCode,
      archived: false,
      slug: dialogResult.slug,
      domain: null,
      branding: {
        accentColor: '#1ED761',
        backgroundColor: '#FFFFFF',
        bannerImages: 
              {
                "16/9": '',
                "3/1": '',
                "3/4": ''
              },
        logoImage: channel.imageUrl,
        themeStyle: 'rounded'
      },
      pages: [],
      organizationId: null
      
    }
    
    const newChannel : any = {
      channelDetails: {
        isMasterChannel: true,
        channelId: newChannelId,
        defaultTags: [],
        defaultEventId: ''
      },
      clubDocId: channel.id,
      channel: newChannelData
    }
    this.channelsService.createNewChannel(newChannel).then(result=>{
      this.isSpinner = false
      this.openSuccessPopup("Successfully created channel")
      this.isPopupOpened = false
      this.dataList[index].isMasterChannel = true
    })
    .catch(error=>{
      this.isSpinner = false
      this.isPopupOpened = false
      const errorText = String(error).includes("SLUG") ? `Slug is already used as a token`: `Slug or Domain should not be empty`
      const data={
        header: "Failed to create new channel experience",
        failureMessage: errorText
      }
      this.dataList[index].hasChannelExperience = !channel.hasChannelExperience
      this.dialogService.openFailureDialog(data)
    })
  }

  ngOnDestroy(){
    if(this.channelSubs) this.channelSubs.unsubscribe()
    this.breadCrumbsService.resetBreadCrumbs()
    this.ngUnsubscribe.next(null)
    this.ngUnsubscribe.complete()
    this.formRestoreService.streamRowId = ''
    this.channelsService.endOfClub=false;
  }
}