<div class="info-body">
    <div class="info-header">
        <h2 class="info-title mb-0" mat-dialog-title>Update Default Tags and Event</h2>
        <button type="button" class="close" (click)="closeDialog()" [disabled]="isSpinner">
            <img src="assets/Channels/cover_image/close.svg" alt="" class="close-icon">
        </button>
    </div>
    <div class="mat-content">
        <ng-container *ngIf="isLoading">
            <div>
                <ngx-skeleton-loader [theme]="{
                    margin: '4px 0 0 23px',
                    'border-radius': '5px',
                    height: '16px',
                    width: '89%'
                }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{
                    margin: '4px 0 0 23px',
                    'border-radius': '5px',
                    height: '42px',
                    width: '89%'
                }"></ngx-skeleton-loader>
            </div>
            <div>
                <ngx-skeleton-loader [theme]="{
                    margin: '10px 0 0 23px',
                    'border-radius': '5px',
                    height: '16px',
                    width: '89%'
                }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{
                    margin: '4px 0 0 23px',
                    'border-radius': '5px',
                    height: '42px',
                    width: '89%'
                }"></ngx-skeleton-loader>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">
            <div class="info-content mob-order-3">
                <div class="info-content-header" [ngClass]="{ 'toggle-off': !isTagsEnabled }">
                    <label class="logo-label">Default Tags</label>

                    <mat-slide-toggle class="mat-toggle" disableRipple (change)="toggleTags()"
                        [checked]="isTagsEnabled"></mat-slide-toggle>
                </div>
                <ng-container *ngIf="isTagsEnabled">
                    <div class="default-tags">

                        <mat-form-field appearance="fill">
                            <mat-chip-list #chipList aria-label="Tag selection" [selectable]="false">
                   <mat-chip *ngFor="let tag of defaultTags; let ind = index" (removed)="removeTag(tag)" disableRipple [selected] = "false" title="{{tag}}">
                                    {{tag | truncateString: 20}}
                                    <img matChipRemove class="close-icon" src="assets/Channels/tag-remove.svg">
                                </mat-chip>
                                <input placeholder="Add tag" [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="addTag($event)" autocomplete="off">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div class="info-content-header">
                    <label class="logo-label">Default Event</label>

                    <mat-slide-toggle class="mat-toggle" disableRipple (change)="toggleEvents()"
                        [checked]="isEventsEnabled"></mat-slide-toggle>
                </div>
                <ng-container *ngIf="isEventsEnabled">
                    <div class="source-div module-fields" style="margin-bottom: 22px;">

            <label for="video-select" class="video-select-div module-select outline-div-input" (click)="dropDownDivOpen('eventSource')" [ngClass]="ddOpen && ddName=='eventSource' ? 'ddOpened' : ''">
                            <img src="assets/Channels/Search.svg" class="input-search">
                <input id="video-select" class="channel-input video" #videoInput [placeholder]="selectedSource.name ? '' : 'Select Events'" (blur)="dropDownDivOpen('')" [(ngModel)]="searchText"
                (keydown.space)="$event.stopPropagation()" (keyup)="searchEvents()" autocomplete="off">
                <span class="video-source display-selected" *ngIf="selectedSource.name && !searchText">{{selectedSource.name}}</span>
                            <img src="assets/Channels/Shape.svg" class="input-arrow">
                <img src="assets/Channels/close.svg" class="input-close" [ngClass]="selectedSource.name && 'show-close'" (click)="onDefaultEventClear($event)">
                        </label>
            <div class="channel-dropdown-panel module-individual" [ngClass]="ddOpen && ddName=='eventSource' ? 'expanded' : ''">
                <span *ngIf="searchText && filterList?.length === 0" class="no-record-found">No results found</span>
                <div class="options-div" *ngFor="let event of eventSourceDocs" [ngClass]="filterSearch(event.name, searchText) ? 'hidden-option' : ''">
                   <span class="option-value" (mousedown)="setSource(event.docId, event.name)" [ngClass]="{'selected': event.name === selectedSource.name}">{{event.name}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-green" [disabled]="disableUpdate()"
            (click)="updateEventsTags()">
            <span *ngIf="isSpinner" class="spinner-border spinner-border-sm spinner-position" role="status"
                aria-hidden="true"></span>
            Save
        </button>
    </div>
</div>