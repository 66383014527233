import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Injectable } from '@angular/core';
import { BulkImportService } from './bulk-import.service';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { isEmpty } from 'lodash';
import { VideoAttributeValue } from '@yoimo/interfaces';
import { lastValueFrom } from 'rxjs';
import { AttributeKey } from '@yoimo/interfaces';

@Injectable({
    providedIn: "root"
  })
export class DownloadCSVService {

    constructor(
        private functions: AngularFireFunctions,
        private bulkService:BulkImportService,
        private http: HttpClient
    ){}
    downloadFile(data: Record<string, any>[], filename='data', headerList: any) {
        let csvData = this.ConvertToCSV(data, headerList);
        this.downloadCSVData(csvData, filename)
    }

    downloadCSVData(csvData: string, filename = 'data') {
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

   async downLoadReferenceSheet(){
        const attributesExcelData:any = []
        const reservedInputExcelData:any = [{}]
        
        const currentAttributeKeys = Object.keys(this.bulkService.attributeMappings).sort() as AttributeKey[]
        currentAttributeKeys.forEach((item:AttributeKey) => {
            const currentAttribute = this.bulkService.attributeMappings[item]
            const attributeHeader =  `attribute_${item[item.length-1]}(${currentAttribute?.label})`
            currentAttribute?.values.forEach((item:VideoAttributeValue,index:number) => {
                if(!attributesExcelData[index]){ attributesExcelData.push({}) }
                attributesExcelData[index][attributeHeader] = item.name  
            })
        })
        this.bulkService.reservedInput.forEach((item:any,index:number) => {
            if(!reservedInputExcelData[index]){ reservedInputExcelData.push({}) }
            reservedInputExcelData[index]['Reserved input ID'] = item.id
        })

        const timeZonesCsvData:any = await lastValueFrom(this.http.get('../../assets/timezones.csv', { responseType: 'text' }));
        const countryCodeCsvData:any = await lastValueFrom(this.http.get('../../assets/country_codes.csv', { responseType: 'text' }));

        let workbook = XLSX.utils.book_new();
        
        const timeZoneSheet = XLSX.utils.aoa_to_sheet(timeZonesCsvData.split('\n').map((row:any) => row.split(',')));
        const countryCodeSheet = XLSX.utils.aoa_to_sheet(countryCodeCsvData.split('\n').map((row:any) => row.split(',')));
        const attributeSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(!isEmpty(attributesExcelData[0]) ? attributesExcelData : [{ 'Attributes':"Attributes are not configured for this channel"}]);
        const ReservedInputSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(!isEmpty(reservedInputExcelData[0]) ? reservedInputExcelData : [{ 'Reserved input ID':"No reserved input details available"}]);
        
        XLSX.utils.book_append_sheet(workbook, timeZoneSheet, 'Time zone');
        XLSX.utils.book_append_sheet(workbook, countryCodeSheet, 'Country code');
        XLSX.utils.book_append_sheet(workbook, attributeSheet, 'Attributes');
        XLSX.utils.book_append_sheet(workbook, ReservedInputSheet, "Reserved input ID");

        let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.download = 'reference_data_sheet.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    ConvertToCSV(objArray: any, headerList: any,noAttributes = true) {
         let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
         let str = '';
         if(array.length >1 && noAttributes){
            let row = 'S. No,';

         for (let index in headerList) {
             row += headerList[index] + ',';
         }
         row = row.slice(0, -1);
         str += row + '\r\n';
         for (let i = 0; i < array.length; i++) {
             let line = (i+1)+'';
             for (let index in headerList) {
                let head = headerList[index];

                 line += ',' + '"' + (array[i][head] ? array[i][head] : '') + '"';
             }
             str += line + '\r\n';
         }
         return str;
         }
         else{
            let row = '';

            for (let index in headerList) {
                row += headerList[index] + ',';
            }
            row = row.slice(0, -1);
            str += row + '\r\n';
            for (let i = 0; i < array.length; i++) {
                let line = '';
                for (let index in headerList) {
                   let head = headerList[index];
   
                    line += '"' + (array[i][head] ? array[i][head] : '') + '"' +',';
                }
                line=line.slice(0,-1)
                str += line + '\r\n';
            }
            return str;
         }
         
     }
    
    async sendEmail(clubDocId: string, exportType: string, startTime: string, endTime: string){
        const response =  await this.functions.httpsCallable('club-export')({
        clubDocId: clubDocId,
        endTime: endTime,
        exportType: exportType,
        startTime: startTime,
       }).toPromise();
       return response
    }
}