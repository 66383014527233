import firebase from "firebase/compat/app";

import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class SettingsService {
  constructor(private firestore: AngularFirestore, private router: Router) { }

  getSettingsData(id: any) {
    // return this.firestore.collection("settings").snapshotChanges();
    return this.firestore.collection("settings" , ref => ref
    .where('backofficeAdminDocIds', 'array-contains', id)
    ).ref.get();
  }

  create(uid: any): Promise<any> {
    return this.firestore.collection('settings').doc("backoffice").update({ "backofficeAdminDocIds": firebase.firestore.FieldValue.arrayUnion(uid) });
  }

  delete(uid: any): Promise<any>{
    return this.firestore.collection("settings").doc("backoffice").update({"backofficeAdminDocIds": firebase.firestore.FieldValue.arrayRemove(uid)});
  }

  async isIdInClubRestrictedFeatures(id: string | string[]){
    const docRef = this.firestore.collection('settings').doc('clubRestrictedFeatures');

    try {
      const docSnapshot = await lastValueFrom(docRef.get());

      const data = docSnapshot.data() as {"NEW_STREAM_CREATION_V2" : string []};
      const arrayField = data.NEW_STREAM_CREATION_V2 || [];
      
      if(Array.isArray(id)){
        return id.map(clubId => arrayField.includes(clubId))
      }
      else{
        return Array.isArray(arrayField) && arrayField.includes(id);
      }

    } catch (error) {
      console.error('Error fetching document:', error);
      return false;
    }
  }

  async updateOTTStreamId(id: string){
    return this.firestore.collection('settings').doc('clubRestrictedFeatures').update({"NEW_STREAM_CREATION_V2" : firebase.firestore.FieldValue.arrayUnion(id)});
  }

  async removeOTTStreamId(id: string){
    return this.firestore.collection('settings').doc('clubRestrictedFeatures').update({"NEW_STREAM_CREATION_V2" : firebase.firestore.FieldValue.arrayRemove(id)});
  }
}
