import { BehaviorSubject, from, lastValueFrom, Observable, of, Subject } from "rxjs";
import { auditTime, first, map, take, tap } from "rxjs/operators";
import { ChannelTabs, Tabs, timeDropDown } from '../home/channels-view/channels-view.component';

import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { deleteField } from "@angular/fire/firestore";
import { Channel, Page, PageModuleList, PageTab } from "@yoimo/interfaces";
import firebase from "firebase/compat/app";
import { chunk, uniq } from "lodash";
import { Sport } from "../interfaces/sports";
import { countryCode } from './country-code.service';

export interface FilterMenu{
  showName: boolean,
  showTime: boolean,
  showCountry: boolean,
  createdFrom: boolean,
  channelType?: boolean
}
export interface FilterInput{
  timeValue: number,
  clubName: string,
  country?: countryCode,
  createdFrom: string,
  channelType?: string
}
export interface NewChannel{
  fullName: string;
  country: string;
  defaultCurrency: string;
  name: string;
  sportDocId: string;
  adminEmails: string[];
  dialCode: string;
  phoneNumber: string;
  description: string;
  isExclusive: boolean;
  coverImageUrl: string;
  imageUrl: string;
  docId: string
  createdFrom: string;
  channelId?:string;
  isMasterChannel?:boolean;
  isOTTCreation?:boolean
}

export interface EditChannel extends NewChannel{
  sportDocIds: string[]
  adminDocIds: string[]
  users: Record<string,any>
  logoUrl?: string;
  sport?: Sport;
  channelDetails?: ChannelDetails;
  defaultAdCuePoints: DefaultAdCuePoints[],
  watermark: Watermark,
}
export interface Watermark {
  enabled: boolean,
  imageUrl: string,
  watermarkFile?: File
}
export interface HoldingImage{
  enabled: boolean,
  imageUrls: any[]
}
export interface DefaultAdCuePoints{
  cueId: string,
  time: 'START' | 'END',
  tag?: string
}
interface ChannelDetails {
  channelId: string | null;
  isMasterChannel: boolean;
  defaultTags?: string[] | firebase.firestore.FieldValue;
  defaultEventId?: string | null | firebase.firestore.FieldValue;
}

export enum ChannelType{
  NEW_CHANNEL = "NEW_CHANNEL",
  ATTACH_CHANNEL = "ATTACH_CHANNEL"
}

export const defaultNamedTagDropDownValues = [
  { key: "Event", value: "EVENT" },
  { key: "Athlete", value: "ATHLETE" },
  { key: "Place", value: "PLACE" },
  { key: "Tournament", value: "TOURNAMENT" },
  { key: "Team", value: "TEAM" },
  { key: "Season", value: "SEASON" },
]

export interface ClubCoverPicture{
  url: string,
  file?: any 
}
export interface Watermark{
  enabled: boolean,
  imageUrl: string,
}

export type TabbedModulesErrors = {
  [tabId: string]: {
    name: boolean;
    order: boolean;
    modules: boolean[];
    icon: boolean;
  };
};

export type InvalidPageError = {
  pageError: TabbedModulesErrors | boolean[];
  type: "tabbed" | "module";
};

export type IconValuesType = {
  [tabId: string]: {
    iconFile: File | null;
    iconUrl?: string;
    iconBuffer?: string;
    invalidUrl: boolean
  }
}

export type SourceDocuments = {
  videoSourceDocs: Record<string, string>[];
  tagSourceDocs: Record<string, string>[];
  eventSourceDocs: Record<string, string>[];
}

interface PageTabWithFlexibleOrder extends Omit<PageTab, 'order'> {
  order: string | number;
}

export interface TabbedPageModules {
  [tabId: string]: PageTabWithFlexibleOrder;
}

@Injectable({
  providedIn: "root",
})
export class ChannelsService {
  tabId:Tabs
  tabIds=Tabs
  channelTabId:ChannelTabs
  channelTabIds=ChannelTabs
  filterMenu: FilterMenu= {showTime:false, showName:false,showCountry:false,createdFrom:false, channelType:false}
  filterInput: Partial<FilterInput>={ clubName:'',
    country:{country:'',code:''}, 
    createdFrom:'All', 
    channelType:'All'
  }
  endOfClub: boolean=false
  private isChannelInfo= new BehaviorSubject<boolean>(false)
  private isEventPage= new BehaviorSubject<boolean>(false)
  public shareBoolean= this.isChannelInfo.asObservable()
  public eventPageBody= this.isEventPage.asObservable()

  private isLoader = new BehaviorSubject<boolean>(false)
  public shareLoader = this.isLoader.asObservable()

  isFormHasValues: boolean=false
  editDocId:string=""
  isEdit:boolean=false
  isHomeTabEdit:boolean=false;
  fromChannelInfo:boolean=false
  selectedTime: timeDropDown | null | undefined
  logoImage:string = ''
  activeHomeTab = 0
  activePageTab = 0
  modules!: PageModuleList | TabbedPageModules 
  private invalidPageModule = new Subject<InvalidPageError>()
  tabbedPagesErrors: TabbedModulesErrors = {}

  iconValues: IconValuesType = {};

  private sourceDocs = new BehaviorSubject<SourceDocuments>({
    videoSourceDocs: [],
    tagSourceDocs: [],
    eventSourceDocs: []
  });
  sourceDocs$ = this.sourceDocs.asObservable();

  
  constructor(private fireStore: AngularFirestore,
    private functions: AngularFireFunctions) {
    this.tabId = this.tabIds.enabled
    this.channelTabId = this.channelTabIds.CLUBS
  }
  getClubsData() {
    return this.fireStore
      .collection("clubs", (ref) => ref.orderBy("createdTime"))
      .valueChanges({ idField: "docId" }).pipe(auditTime(700));
  }
  getClubsDataSortedByName() {
    return this.fireStore
    .collection("clubs", (ref) => ref.orderBy("name","asc"))
    .valueChanges({idField:"docId"}).pipe(auditTime(700));
  }
  getArchivedClubsData() {
    return this.fireStore
      .collection("archive", (ref) => ref.orderBy("createdTime"))
      .valueChanges({ idField: "docId" })
      .pipe(
        map((result) => {
          return result.map((record) => {
            return { isArchived:true, ...record };
          });
        })
      ).pipe(auditTime(700));
  }
  getClubById(id:string){
    return this.fireStore.collection("clubs").doc(id).valueChanges({idField:"docId"})
  }
  getClubByIdPromise(id:string){
    return this.fireStore.collection("clubs").doc(id).ref.get()
  }
  deleteClub(clubId:string){
    return this.fireStore.collection("clubs").doc(clubId).delete()
  }
  retrieveClub(clubId:string){
    return this.fireStore.collection("archive").doc(clubId).delete()
  }
  setChannelInfo(){
    this.isChannelInfo.next(true)
  }
  setEventPage(){
    this.isEventPage.next(true)
  }
  resetChannelInfo(){
    this.isChannelInfo.next(false)
  }
  resetEventPageInfo(){
    this.isEventPage.next(false)
  }

  setLoaderStatus(){
    this.isLoader.next(true)
  }

  resetLoaderStatus(){
    this.isLoader.next(false)
  }

  getArchivedById(id:string){
    return this.fireStore.collection('archive').doc(id).valueChanges()
  }
  checkLiveOrArchive(id:string){
    return this.fireStore.collection('clubs').doc(id).get()
  }

  getSubscriptionById(subscriptionId:string){
   return this.fireStore.collection("subscriptions").doc(subscriptionId).valueChanges()
  }
  getClubByName(collection: string,name: string){
    return this.fireStore.collection(collection, ref=>ref.orderBy("name")
    .startAt(name.toUpperCase()).endAt(name.toLowerCase() +'\uf8ff'))
    .valueChanges({idField:"id"}).pipe(auditTime(1400))
  }
  getClubByType(collection: string){
    return this.fireStore.collection(collection, ref=>ref.orderBy("channelDetails"))
    .valueChanges({idField:"id"}).pipe(auditTime(1400))
  }
  getClubsDataAll(collection:string){
    return this.fireStore.collection(collection, ref=>ref.orderBy("name"))
    .valueChanges({idField:"id"}).pipe(auditTime(1400))
  }
  getClubByTime(collection:string, dateRange: any){
    return this.fireStore.collection(collection, ref=> ref
    .where('createdTime', '>=', new Date(dateRange))).valueChanges({idField: "id"}).pipe(auditTime(1500))
  }
  getClubByCountry(collection:string, country:string){
    return this.fireStore.collection(collection,ref=>ref
      .where("country","==",country)).valueChanges({idField:"id"}).pipe(auditTime(2000))
  }
  getClubByCreated(collection:string){
    return this.fireStore.collection(collection, (ref) => ref.orderBy("createdTime")).valueChanges({idField:"id"}).pipe(auditTime(2000))
  }
  getBatch(collection: string,lastSeen: string){
    return this.fireStore.collection(collection, ref=>ref.orderBy("name").startAfter(lastSeen)
    .limit(50))
      .snapshotChanges()
    .pipe(auditTime(400),take(1),
      tap(arr=>(arr.length ? null : this.endOfClub=true)),
      map(arr=>{
        return arr?.reduce((acc,cur)=>{
          const id=cur.payload.doc.id
          const data= cur.payload.doc.data() as {}
          return { ...acc,[id]:{id,...data}}
        },{})
        })
      );
  }

  async getClubssByIdArray(values:string[], collection:string){
    const docID = firebase.firestore.FieldPath.documentId()
    return (
      await Promise.all(
        chunk(values,10).map(
          slice => {
            let query = this.fireStore.collection(collection).ref.where(docID,"in",slice);
          return query.get();
          }
        )
      )
    ).reduce((acc:any, batchResult) => [...acc, ...batchResult.docs], []);
  };
  async getUserDocId(values:string[]){
    return (
      await Promise.all(
        chunk(values,10).map(
          slice => {
            let query = this.fireStore.collection("subscriptions").ref.where("clubDocId","in",slice);
          return query.get();
          }
        )
      )
    ).reduce((acc:any, batchResult) => [...acc, ...batchResult.docs], []);
  };
  getMembersOfClub(collection: string,clubDocId: string){
    return this.fireStore.collection(collection).doc(clubDocId).collection("members")
    .valueChanges({idField: "docId"})
  }
  removeAdminFromChannel(docId: string, adminDocIds:string[]){
    return from( this.fireStore.collection("clubs").doc(docId).update({
          adminDocIds: firebase.firestore.FieldValue.arrayRemove(
            ...adminDocIds
      )
    }))
  }
  removeTrainerFromChannel(docId: string, trainerDocIds:string[], teamDocIds: string[]){
    let result: boolean =false
    try{
      teamDocIds.forEach(teamId=>{
        this.fireStore.collection("teams").doc(teamId).update({
          trainerDocIds: firebase.firestore.FieldValue.arrayRemove(...trainerDocIds)
        })

      })
    }
    catch(error){
      if(error) result=true
    }
    finally{
      return of(result)
    }
  }
  removeStreamerFromChannel(docId: string, streamerDocIds:string[]){
    return from(this.fireStore.collection("clubs").doc(docId).update({
          streamerDocIds: firebase.firestore.FieldValue.arrayRemove(
            ...streamerDocIds
      )
    }))
  }
  removeMemberFromChannel(clubDocId: string,membersDocIds: string[]){
    return this.functions.httpsCallable('users-removeMembersOfClub')
    ({ clubDocId, userDocIds: membersDocIds }).pipe(map(r => r.success));
  }
  getClubInfo(collection:string, clubDocId: string){
    return this.fireStore.collection(collection).doc(clubDocId).valueChanges({idField:"docId"})
  }

  async createChannel(newChannel: NewChannel){
    const result = await this.functions.httpsCallable('club-register')({club: newChannel}).toPromise()
    return result
  }
  async updateChannel(channelData: Partial<EditChannel>,docId:string){
    return this.fireStore.collection("clubs").doc(docId).set(channelData, {merge: true})
  }
  async bulkDelete(bulkImportDocId: string){
    const response = await this.functions
          .httpsCallable("livestreams-deleteBulkImport")({bulkImportDocId})
      .toPromise();

    // return response.data
  }
  editClubLogo(docId:string, url:String){
    return this.fireStore.collection("clubs").doc(docId).update({imageUrl:url})
  }

  editBrandingLogo(docId:string, url:String){
    return this.fireStore.collection("channels").doc(docId).update({"branding.logoImage" : url})
  }


  updateHoldingImages(docId:string, obj:Record<string,any>){
    this.fireStore.collection("clubs").doc(docId).collection("holdingImages").doc("holdingImages").set(obj);
  }

  updateWatermark(docId:string, obj:Record<string,any>){
    return this.fireStore.collection("clubs").doc(docId).collection("watermark").doc("watermark").set(obj);
  }

  getHoldingImages(docId:string){
    return this.fireStore.collection("clubs").doc(docId).collection("holdingImages").doc("holdingImages").ref.get()
  }

  getWatermark(docId:string){
    return this.fireStore.collection("clubs").doc(docId).collection("watermark").doc("watermark").ref.get()
  }

  getClubOrClubRegistrationById(collection: string,id:string){
    return this.fireStore.collection(collection).doc(id).valueChanges()
  }

  addChannelHighlights(highlightDocId: string, channelDocId: string){
    return this.fireStore.collection('clubs').doc(channelDocId).update({
      clips: firebase.firestore.FieldValue.arrayUnion(
        highlightDocId
      ),
    })
  }
  removeChannelHighlights(highlightDocId: string, channelDocId: string){
    return this.fireStore.collection('clubs').doc(channelDocId).update({
      clips: firebase.firestore.FieldValue.arrayRemove(
        highlightDocId
      ),
    })
  }
  reArrangeHighlights(channelDocId: string, highlightsId: string[]){
    return this.fireStore.collection('clubs').doc(channelDocId).update({
      clips: highlightsId
    })
  }
  deleteHighlight(channelDocId: string, highlightId: string){
    return this.fireStore.collection('clubs').doc(channelDocId).update({
      clips: firebase.firestore.FieldValue.arrayRemove(highlightId)
    })
  }

  updateAdPreroll(channelDocId:string,obj:Record<string,string>, status: boolean){
    if(status){
      return this.fireStore.collection('clubs').doc(channelDocId).update({
        defaultAdCuePoints: firebase.firestore.FieldValue.arrayRemove(
          obj
        ),
        });
    }
    else{
      return this.fireStore.collection('clubs').doc(channelDocId).update({
        defaultAdCuePoints: firebase.firestore.FieldValue.arrayUnion(
          obj
        ),
        });
    }
  }

  manageCueTag(docId: string, oldObj:Record<string,string>, newObj:Record<string,string>){
    const addCuesRef = this.fireStore.collection('clubs').doc(docId)
    return addCuesRef.update(
      {
        defaultAdCuePoints: firebase.firestore.FieldValue.arrayRemove(oldObj)
      }
    ).then(() => {
        addCuesRef.update({
          defaultAdCuePoints: firebase.firestore.FieldValue.arrayUnion(newObj) 
        })
      }
    )
  }
  updateArchivedChannel(email: string,docId:string){
    return this.fireStore.collection("archive").doc(docId).update({unarchiveEmail:email})
  }

  updateNewChannel(docId: string, obj: any){
    return this.fireStore.collection("channels").doc(docId).update(obj)
  }

  updateNewChannelData(docId: string, obj: any, pagePropsToDelete: Partial<keyof Page>[] = []): Promise<void> {
    let defaultData:Page[] = [
      {
        'active':true,
        'metaTags':[],
        'modules':[],
        'name':'',
        'status':'PUBLISHED',
      }
    ]
    const channelCollectionRef = this.fireStore.collection('channels');

    return new Promise<void>((resolve, reject) => {
      channelCollectionRef.doc(docId).get().subscribe((doc) => {
          if (doc.exists) {
            const channelData: any = doc.data();
          let pages = channelData.pages.length ? channelData.pages : defaultData; 

            pages[0] = { ...pages[0], ...obj };
            for (const property of pagePropsToDelete) {
              if (pages[0][property]) delete pages[0][property];
            }

          channelCollectionRef.doc(docId).update({
                pages: pages,
              })
              .then(() => {
                resolve(); // Resolve the promise if the update is successful
              })
              .catch((error) => {
            console.error('Error updating document: ', error);
                reject(error); // Reject the promise if there's an error
              });

          } else {
          reject(new Error('Document does not exist.'));
          }
        });
    });
  }

  updateMetaTags(docId: string, obj: any){
    return this.fireStore.collection("channels").doc(docId).update(obj)
  }


  updateChannelBranding(docId:string, obj:any){
    return this.fireStore.collection("channels").doc(docId).set({branding: obj}, {merge: true })
  }

  updateChannelNavigation(docId:string, obj:any){
    return this.fireStore.collection("channels").doc(docId).set({channelNavigation: obj.length ? obj : deleteField()}, {merge: true })
  }

  updateBrandingBannerImages(docId:string, obj:any){
    return this.fireStore.collection("channels").doc(docId).set({branding: {bannerImages: obj}}, {merge: true })
  }

  updateBrandingContactInfo(docId:string, isContactInfo:boolean, obj:any){
    if(!isContactInfo) return this.fireStore.collection("channels").doc(docId).set({branding: {contactInformation: obj}}, {merge: true })
    else return this.fireStore.collection("channels").doc(docId).update({
      "branding.contactInformation" : firebase.firestore.FieldValue.delete()
    })
  }

  removeFavicon(docId: string){
    return this.fireStore.collection("channels").doc(docId).update({
      "branding.favicon" : firebase.firestore.FieldValue.delete()
    })
  }

  async getNewChannelsData(){
    const dataRef = await this.fireStore.collection("channels").ref.get()
    return dataRef
  }

  getLastLiveStream(channelId: string){
    return new Promise((resolve, reject) => {
        const query = this.fireStore.collection("channels").doc(channelId).collection('videos' , (ref) => ref.orderBy('endTime', 'desc').limit(1))

          const subscription = query.valueChanges().pipe(first()).subscribe({
          next: (querySnapshot) => {
            if (querySnapshot.length > 0) {
              // If there are documents in the query result
              const latestStream = querySnapshot[0];
              resolve(latestStream.endTime.toDate());
            } else {
                resolve('N/A');
            }
          },
          error: (error) => {
            reject(error);
          },
          complete: () => {
            subscription.unsubscribe(); // Clean up the subscription
            }
        });
    });
  }

  getNewChannelById(channelId: string): Observable< Channel | undefined>{
    return this.fireStore.collection<Channel>("channels").doc(channelId).valueChanges({idField: "docId"})
  }

  async getChannelById(channelId: string): Promise<Channel>{
    return ((
      await lastValueFrom(
        this.fireStore
          .collection("channels")
          .doc(channelId)
          .get()
      )
    ).data() || {}) as Channel;
  }

  getOrganizationChannel(docId: string, collection: string){
    return this.fireStore.collection(collection).doc(docId).ref.get()
  }
  async createNewChannel(newChannel: any){
    const result = await this.functions
      .httpsCallable("channel-createChannel")(newChannel)
      .toPromise();
  }
  getAttachedChannels(newChannelId: string){
    return this.fireStore.collection("clubs", ref=>ref
    .where("channelDetails.channelId","==",newChannelId)).valueChanges({idField: "docId"}).pipe(auditTime(1000))
  }

  async getAttachedChannelsPromise(newChannelId:string){
    return this.fireStore.collection("clubs", ref=>ref
    .where("channelDetails.channelId","==",newChannelId)).get().pipe(
      map(channels => {
        return channels.docs.map(doc => {
            const data = doc.data() as any;
            const docId = doc.id;
            return { docId, ...data };
          });
        })
    ).toPromise()
  }

  async getChannelRatings(data: { getChannelRating: boolean; channelId?: string; startDate?: Date; endDate?: Date; videoId?:string}){
    const response = await lastValueFrom(this.functions.httpsCallable('channel-getchannelrating')(data))
    return response
  }

  splitTags(existingTags: string[], tags:string): string[] {
   let updatedTags = []
   if(tags.includes(',')){
     updatedTags = tags.split(/\s*,\s*/)
   }
   else{
    updatedTags = [tags]
    }

   return uniq(updatedTags.filter(tag => tag.trim() !== '' && !existingTags.includes(tag.trim())));
  }

  setModuleError(invalidPageError: InvalidPageError): void {
    if(invalidPageError.type == 'tabbed'){
      this.tabbedPagesErrors = invalidPageError.pageError as TabbedModulesErrors
    }
    this.invalidPageModule.next(invalidPageError);
  }

  getModuleError(): Observable<InvalidPageError> {
    return this.invalidPageModule.asObservable();
  }

  updateSourceDocs(newDocs: Partial<SourceDocuments>) {
    const currentDocs = this.sourceDocs.getValue();
    this.sourceDocs.next({ ...currentDocs, ...newDocs });
  }
} 
